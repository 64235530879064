import { ChangeEvent } from 'react';

import { TTableColumnsFilter, useTableContext } from '@shared/components/Table';
  
interface IOption {
  id: string;
  label: string;
}

interface IProps {
  name: string;
  options: IOption[];
}

export const FilterSelect = <T,>({ name, options }: IProps) => {
  const { currentFilter, updateFilter } = useTableContext<T>();

  const filterKey = `filter[${String(name)}]`;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    updateFilter({ [filterKey]: value } as unknown as TTableColumnsFilter<T>);
  };

  return (
    <select
      className='table-filter-input'
      data-filter-term={filterKey}
      onChange={handleChange}
      value={currentFilter.get(filterKey) || ''}
    >
      <option value=''>All</option>
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
