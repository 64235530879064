import Cookies from 'js-cookie';

import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILoginResponse } from '../model/types';

interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: Cookies.get('authToken') || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to handle successful login
    setToken: (state, action: PayloadAction<ILoginResponse>) => {
      Cookies.set('authToken', action.payload.token, {
        expires: 7,
        secure: true,
        sameSite: 'strict',
      });
      state.token = action.payload.token;
    },
    // Action to clear token on logout
    clearToken: state => {
      Cookies.remove('authToken');
      state.token = null;
    },
  },
});

export const actions = authSlice.actions;
export default authSlice.reducer;

export const useToken = () => {
  return useTypedSelector((state: RootState) => state.auth.token);
};
