import { useState, useEffect, useCallback } from 'react';

import { ZodSchema, z } from 'zod';

import { TFetchQueryError } from '@shared/types/fetch-data';

interface IIssue {
  message: string;
  path: string;
}

export interface IZodValidationError {
  type: 'zod';
  issues: IIssue[];
}

interface IZodValidationResponse<T> {
  data: T | null;
  error: IZodValidationError | null;
}

interface ISafeParseSuccess<T> {
  success: true;
  data: T;
}

interface ISafeParseError<T> {
  success: false;
  error: z.ZodError<T>;
}

type SafeParseResult<T> = ISafeParseSuccess<T> | ISafeParseError<T>;

function isSafeParseError<T>(result: SafeParseResult<T>): result is ISafeParseError<T> {
  return result.success === false;
}

interface IProps<T> {
  response: unknown;
  schema: ZodSchema<T>;
  isError: boolean;
  error: TFetchQueryError | IZodValidationError;
}

export const useZodDataValidation = <T>({ response, schema, isError, error }: IProps<T>) => {
  const [validatedResponse, setValidatedResponse] = useState<IZodValidationResponse<T>>({
    data: null,
    error: null,
  });

  const validate = useCallback(
    (data: unknown) => {
      const result = schema.safeParse(data);

      if (result.success) {
        setValidatedResponse({ data: result.data, error: null });
      } else if (isSafeParseError(result)) {
        const issues = result.error.issues.map(issue => ({
          message: issue.message,
          path: issue.path.join('.'),
        }));

        setValidatedResponse({
          data: null,
          error: {
            type: 'zod',
            issues,
          },
        });
      }
    },
    [schema],
  );

  useEffect(() => {
    if (response) {
      validate(response);
    }
  }, [response, validate]);

  const hasError = validatedResponse.error ? true : isError;
  const errorMessage = validatedResponse.error ? validatedResponse.error : error;

  return {
    hasError,
    errorMessage,
  };
};
