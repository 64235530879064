import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useMerchants } from '@entities/merchant';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  name?: string;
  email?: string;
  domain?: string;

  onChangeMerchant?: (merchantId: string) => void;
}

export const MerchantsSelect: FC<IProps> = ({
  name = '',
  email = '',
  domain = '',
  onChangeMerchant,
}) => {
  const { merchants, isLoading, isFetching, error } = useMerchants({
    name,
    email,
    domain,

    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (merchants) {
    return (
      <Select
        name='merchant_id'
        options={merchants.data}
        getOptionValue={merchant => merchant.id}
        getOptionLabel={merchant => merchant.short_name}
        onChange={onChangeMerchant}
        isDisabled={isFetching}
      >
        <option value=''>Select merchant</option>
      </Select>
    );
  }

  return null;
};
