import { FC } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IconButton, Tooltip } from '@mui/material';

interface IProps {
  text: string;
}

const CopyToClipboardButton: FC<IProps> = ({ text }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <Tooltip title='Copy' placement='right'>
      <IconButton onClick={copyToClipboard} aria-label='copy'>
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
