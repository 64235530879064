import { usePageTitle } from '@app/hooks/usePageTitle';
import SettlementsWidget from '@widgets/settlements';

const SettlementsPage = () => {
  usePageTitle('Settlements');

  return <SettlementsWidget />;
};

export default SettlementsPage;
