import { baseApi } from '@app/store/index.api';

import { IDashboardResponse, IDashboardCurrenciesResponse } from '../model/types';

const dashboardApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    dashboard: builder.query<IDashboardResponse, string>({
      query: query => {
        return {
          url: `/dashboard/dashboard?${query}`,
        };
      },
    }),
    dashboardCurrencies: builder.query<IDashboardCurrenciesResponse, void>({
      query: () => {
        return {
          url: '/dashboard/currencies',
        };
      },
    }),
  }),
});

export const { useLazyDashboardQuery, useDashboardCurrenciesQuery } = dashboardApi;
