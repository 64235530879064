type ObjectKeys<T> = { [K in keyof T]: K extends string ? K : never }[keyof T];
type ObjectValues<T> = T[keyof T];
type ObjectEntries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

export function getTypedObjectKeys<T>(obj: T): ObjectKeys<T>[] {
  return Object.keys(obj) as ObjectKeys<T>[];
}

export function getTypedObjectValues<T>(obj: T): ObjectValues<T>[] {
  return Object.values(obj) as ObjectValues<T>[];
}

export function getTypedObjectEntries<T extends object>(obj: T): ObjectEntries<T>[] {
  return Object.entries(obj) as ObjectEntries<T>[];
}

export function filterTypedArray<T>(arr: T[], filter: (item: T) => boolean): T[] {
  return arr.filter(filter) as T[];
}
