import { FC } from 'react';

import { useParserTaskQuery } from '@entities/parser-task';
import { TRequestType } from '@entities/requests';
import DataTableView from '@shared/components/DataTableView';
import LoadingSpinner from '@shared/components/LoadingSpinner';

interface IProps {
  type: TRequestType;
  id: string;
}

export const ViewParserTask: FC<IProps> = ({ id, type }) => {
  const { data: parserTaskInfo, isLoading } = useParserTaskQuery({ id, type });

  if (isLoading) return <LoadingSpinner />;

  if (parserTaskInfo) {
    return (
      <div className='request-info'>
        <DataTableView data={parserTaskInfo.data} />
      </div>
    );
  }

  return null;
};
