import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsMIDsWidget from '@widgets/settings/mids';

const SettingsMIDsPage = () => {
  usePageTitle('MIDs');

  return <SettingsMIDsWidget />;
};

export default SettingsMIDsPage;
