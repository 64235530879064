import { baseApi } from '@app/store/index.api';

import { IRequestWithdrawalResponse } from '../model/types';

const requestWithdrawalApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    requestWithdrawal: builder.query<IRequestWithdrawalResponse, string>({
      query: query => {
        return {
          url: `/requests/withdrawals?${query}`,
        };
      },
    }),
  }),
});

export const { useRequestWithdrawalQuery } = requestWithdrawalApi;
