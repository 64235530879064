import { FormEvent, useState } from 'react';

import {
  IMerchantResetPasswordQuery,
  useResetMerchantPasswordMutation,
} from '@entities/merchant/user';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';

export const ResetPassword = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [resetPassword, { isLoading, isSuccess, isError, error }] =
    useResetMerchantPasswordMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formValues = Object.fromEntries(
      formData.entries(),
    ) as unknown as IMerchantResetPasswordQuery;

    try {
      const result = await resetPassword(formValues);

      if ('data' in result) {
        setSuccessMessage(result.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <form onSubmit={handleSubmit}>
        <FormItemWithError
          name='old_password'
          label='Current password'
          isError={isError}
          error={error}
        >
          <input type='password' name='old_password' />
        </FormItemWithError>

        <FormItemWithError name='new_password' label='New password' isError={isError} error={error}>
          <input type='password' name='new_password' />
        </FormItemWithError>

        <FormItemWithError
          name='new_password_confirmation'
          label='Confirm password'
          isError={isError}
          error={error}
        >
          <input type='password' name='new_password_confirmation' />

          {isSuccess && <div className='form-success'>{successMessage}</div>}
        </FormItemWithError>

        <div className='form-submit'>
          <button className='accent-btn' type='submit' disabled={isLoading || isSuccess}>
            Change password
          </button>
        </div>
      </form>
    </>
  );
};
