import { useEffect } from 'react';

import { useTableContext } from '@shared/components/Table';

interface InitialObject {
  id: string;
  status: string;
}

interface IProps<T> {
  items: T[];
  filterKeys?: string[];
}

const TableCheckAllRows = <T extends InitialObject>({ items, filterKeys }: IProps<T>) => {
  const { handleCheckAll, enabledIds, setEnabledIds, selectedIds } = useTableContext();

  useEffect(() => {
    const newEnabledIds = items
      .filter(item => {
        if (!filterKeys) {
          return true;
        }

        return filterKeys.includes(item.status.toLowerCase());
      })
      .map(item => item.id);

    setEnabledIds(newEnabledIds);
  }, [items, filterKeys, setEnabledIds]);

  const isAllSelectedOnCurrentPage =
    enabledIds.length === 0 ? false : enabledIds.every(id => selectedIds.includes(id));

  return (
    <input
      type='checkbox'
      className='checkbox-all'
      onChange={handleCheckAll}
      checked={isAllSelectedOnCurrentPage}
      disabled={!enabledIds.length}
    />
  );
};

export default TableCheckAllRows;
