import { FC, useEffect, useRef } from 'react';
import './pie.css';

interface IProps {
  percent: number;
  fillColor: string;
}

const RADIUS = 40;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const calculateStrokeDashoffset = (percent: number, circumference: number) =>
  (1 - percent / 100) * circumference;

export const PieChart: FC<IProps> = ({ percent, fillColor }) => {
  const fillRef = useRef<SVGCircleElement | null>(null);

  useEffect(() => {
    if (fillRef.current) {
      const offset = calculateStrokeDashoffset(percent, CIRCUMFERENCE);

      fillRef.current.style.transition = 'none';
      fillRef.current.style.strokeDashoffset = `${CIRCUMFERENCE}px`;

      requestAnimationFrame(() => {
        if (fillRef.current) {
          fillRef.current.style.transition = 'stroke-dashoffset 0.5s ease-in-out';
          fillRef.current.style.strokeDashoffset = `${offset}px`;
        }
      });
    }
  }, [percent]);

  return (
    <div className='pie-chart-container'>
      <svg className='pie-chart' width='100' height='100' viewBox='0 0 100 100'>
        <circle cx='50' cy='50' r={RADIUS} fill='none' stroke='#f4f4f4' strokeWidth='10'></circle>
        <circle
          ref={fillRef}
          className='pie-chart-fill'
          cx='50'
          cy='50'
          r={RADIUS}
          fill='none'
          stroke={fillColor}
          strokeWidth='6'
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={CIRCUMFERENCE}
        ></circle>
      </svg>
      <div className='percentage' style={{ color: fillColor }}>
        {percent}%
      </div>
    </div>
  );
};
