import { baseApi } from '@app/store/index.api';

import {
  IWallet,
  TWalletCredentials,
  IWalletUpdateQuery,
  IWalletUpdateStatusQuery,
  IWalletUpdateStatusResponse,
  IWalletUpdateModeQuery,
  IWalletUpdateModeResponse,
} from '../model/types';

const walletsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    createWallet: builder.mutation<IWallet, FormData>({
      query: formData => ({
        url: '/wallets',
        method: 'POST',
        body: formData,
      }),
    }),

    updateWallet: builder.mutation<IWallet, IWalletUpdateQuery>({
      query: query => ({
        url: `/wallets/${query.id}`,
        method: 'PATCH',
        body: query,
      }),
    }),

    updateWalletStatus: builder.mutation<IWalletUpdateStatusResponse, IWalletUpdateStatusQuery>({
      query: query => ({
        url: `/wallets/${query.id}/status`,
        method: 'PATCH',
        body: {
          status: query.status,
        },
      }),
    }),

    updateWalletMode: builder.mutation<IWalletUpdateModeResponse, IWalletUpdateModeQuery>({
      query: query => ({
        url: `/wallets/${query.id}/mode`,
        method: 'PATCH',
        body: {
          mode: query.mode,
        },
      }),
    }),

    getWalletCredentials: builder.query<TWalletCredentials, string>({
      query: id => `/wallets/${id}/credentials`,
    }),
  }),
});

export const {
  useCreateWalletMutation,
  useUpdateWalletMutation,
  useUpdateWalletStatusMutation,
  useUpdateWalletModeMutation,
  useLazyGetWalletCredentialsQuery,
} = walletsApi;
