import { FC } from 'react';

import { AuthControl } from '@features/auth-control';
import DateTime from '@features/date-time';
import PageTitle from '@features/page-title';
import ToggleSidebar from '@features/toggle-sidebar';

import './header.css';

interface IProps {
  isMobSidebarOpen: boolean;
  setMobIsSidebarOpen: (value: boolean) => void;
}

const Header: FC<IProps> = ({ isMobSidebarOpen, setMobIsSidebarOpen }) => {
  return (
    <header>
      <ToggleSidebar
        setMobIsSidebarOpen={setMobIsSidebarOpen}
        isMobSidebarOpen={isMobSidebarOpen}
      />

      <PageTitle />

      <DateTime />

      <div className='header-right-block'>
        <AuthControl />
      </div>
    </header>
  );
};

export default Header;
