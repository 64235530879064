import { FC, PropsWithChildren } from 'react';

import { Tooltip } from '@mui/material';

interface IProps extends PropsWithChildren {
  text: string;
}

const HighlightIcon: FC<IProps> = ({ text, children }) => {
  return (
    <Tooltip placement='top' title={text}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default HighlightIcon;
