import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsMerchantsWidget from '@widgets/settings/merchants';

const SettingsMerchantsPage = () => {
  usePageTitle('Merchants');

  return <SettingsMerchantsWidget />;
};

export default SettingsMerchantsPage;
