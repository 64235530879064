import { useState, useEffect } from 'react';

import { useTimeZone } from '@entities/datetime';
import TimeZoneSelect from '@features/date-timezone-select';

const DateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const timeZone = useTimeZone();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const options = {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as const;

  const formattedTime = new Intl.DateTimeFormat(undefined, options).format(currentTime);

  return (
    <div className='date-time-block'>
      <TimeZoneSelect />
      <div className='date-time'>{formattedTime}</div>
    </div>
  );
};

export default DateTime;
