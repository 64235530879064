import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsCurrencyMethodsWidget from '@widgets/settings/currency-methods';

const SettingsCurrencyMethodsPage = () => {
  usePageTitle('Currency Methods');

  return <SettingsCurrencyMethodsWidget />;
};

export default SettingsCurrencyMethodsPage;
