import { FC } from 'react';

import './emoji-icon.css';

interface IProps {
  describedby?: string;
  children: string;
}

const EmojiIcon: FC<IProps> = ({ describedby, children }) => {
  return (
    <span aria-describedby={describedby} role='img' className='emoji-icon' aria-hidden='false'>
      {children}
    </span>
  );
};

export default EmojiIcon;
