import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetIPsQuery } from '../api';
import type { IIPWhitelistFilter } from '../model/types';

interface IProps {
  ip?: string;
}

export const useIPWhitelist = ({ ip }: IProps = {}) => {
  const filter = useFilter<IIPWhitelistFilter>();
  const { currentFilter, updateFilter } = filter;

  const {
    data: ipWhitelist,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetIPsQuery(currentFilter.toString());

  useEffect(() => {
    updateFilter({ 'filter[ip]': ip });
  }, [ip]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ipWhitelist,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
