import { TOrderType } from '@entities/orders';
import { useOrderDeposits } from '@entities/orders/deposit';
import { useOrderWithdrawals } from '@entities/orders/withdrawal';

export const getMutationHook = (type: TOrderType) => {
  switch (type) {
    case 'deposit':
      return useOrderDeposits;
    case 'withdrawal':
      return useOrderWithdrawals;
  }
};
