import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetMethodsQuery } from '../api';
import type { IMethodFilter } from '../model/types';

interface IProps {
  merchantId?: string;
  currencyId?: string;
  midIds?: string;

  disableUrlFilter?: boolean;
}

export const useMethods = ({ merchantId, currencyId, midIds, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<IMethodFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter } = filter;

  const {
    data: methods,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetMethodsQuery(currentFilter.toString());

  useEffect(() => {
    updateFilter({ 'filter[merchant_id]': merchantId });
  }, [merchantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[currency_id]': currencyId });
  }, [currencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[mid_ids]': midIds });
  }, [midIds]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    methods,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
