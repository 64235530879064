import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useMIDs } from '@entities/mid';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';
import { getMIDName } from '../utils/getMIDName';

interface IProps {
  merchantIds?: string[];
  currencyId?: string;
  methodId?: string;

  selectedMID?: string;
  onChangeMID?: (MIDId: string) => void;
}

export const MIDsSelect: FC<IProps> = ({
  merchantIds = [],
  currencyId = '',
  methodId = '',

  selectedMID,
  onChangeMID,
}) => {
  const { mids, isLoading, error, isFetching } = useMIDs({
    merchantIds,
    currencyId,
    methodId,

    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (mids) {
    const hasMIDs = mids.data.length > 0;
    const selectedMIDId = mids.data.find(mid => getMIDName(mid) === selectedMID)?.id;

    return (
      <Select
        name='mid_id'
        options={mids.data}
        getOptionValue={mid => mid.id}
        getOptionLabel={getMIDName}
        selectedValue={selectedMIDId}
        onChange={onChangeMID}
        isDisabled={isFetching || !hasMIDs}
      >
        <option value=''>Select MID</option>
      </Select>
    );
  }

  return null;
};
