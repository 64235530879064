import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetCurrenciesQuery } from '../api';
import type { ICurrencyFilter } from '../model/types';

interface IProps {
  merchantId?: string;
  mid_ids?: string;

  disableUrlFilter?: boolean;
}

export const useCurrencies = ({ merchantId, mid_ids, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<ICurrencyFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter } = filter;

  const {
    data: currencies,
    isLoading,
    isError,
    error,
    isFetching,
    isSuccess,
    refetch,
  } = useGetCurrenciesQuery(currentFilter.toString());

  useEffect(() => {
    updateFilter({ 'filter[merchant_id]': merchantId });
  }, [merchantId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[mid_ids]': mid_ids });
  }, [mid_ids]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    currencies,
    isError,
    error,
    isLoading,
    isFetching,
    isSuccess,
    refetch,

    filter,
  };
};
