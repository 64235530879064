import { useState, useEffect, useRef } from 'react';

import DatePicker from 'react-datepicker';

import { TTableColumnsFilter, useTableContext } from '@shared/components/Table';
import { formatDateAsLocalISO } from '@shared/utils/formatDateAsLocalISO';

import 'react-datepicker/dist/react-datepicker.css';

type TDateRange = [Date | null, Date | null];

interface IProps<T> {
  name: keyof T;
}

export const FilterDateRange = <T,>({ name }: IProps<T>) => {
  const { currentFilter, updateFilter } = useTableContext<T>();

  const [dateRange, setDateRange] = useState<TDateRange>([null, null]);
  const [startDate, endDate] = dateRange;
  const keysRef = useRef<string[]>([]);

  useEffect(() => {
    keysRef.current = [`filter[${String(name)}][from]`, `filter[${String(name)}][to]`];
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const [startKey, endKey] = keysRef.current;

    const newStartDate = currentFilter.get(startKey)
      ? new Date(String(currentFilter.get(startKey)))
      : null;
    const newEndDate = currentFilter.get(endKey)
      ? new Date(String(currentFilter.get(endKey)))
      : null;
    setDateRange([newStartDate, newEndDate]);
  }, [currentFilter]);

  const handleChange = (dates: TDateRange) => {
    const [startDate, endDate] = dates;
    const [startKey, endKey] = keysRef.current;

    setDateRange(dates);

    if (startDate && endDate) {
      const newFilter = {
        [startKey]: formatDateAsLocalISO(startDate),
        [endKey]: formatDateAsLocalISO(endDate),
      } as TTableColumnsFilter<T>;

      updateFilter(newFilter);
    }

    if (startDate === null && endDate === null) {
      const newFilter = {
        [startKey]: '',
        [endKey]: '',
      } as TTableColumnsFilter<T>;

      updateFilter(newFilter);
    }
  };

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={handleChange}
      isClearable={true}
      placeholderText='Select date range'
    />
  );
};
