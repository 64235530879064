import { ReactNode } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { IBaseObject } from '@shared/types/fetch-data';
import { getTypedObjectKeys } from '@shared/types/typedObjectEntries';

import { useTableContext } from '../context';
import { TTableColumnTitles } from '../model/types';

interface IProps<T, K extends Partial<TTableColumnTitles<T>>> {
  items: T[];
  renderItem: (key: keyof K, item: T) => ReactNode;
  beforeColumns?: (item: T) => ReactNode;
  afterColumns?: (item: T) => ReactNode;
}

export const TBody = <T extends IBaseObject, K extends Partial<TTableColumnTitles<T>>>({
  items,
  renderItem,
  beforeColumns,
  afterColumns,
}: IProps<T, K>) => {
  const { columnTitles, visibleColumns } = useTableContext<T>();

  return (
    <tbody key={uuidv4()}>
      {!items.length && (
        <tr>
          <td colSpan={Object.keys(columnTitles).length}>No data</td>
        </tr>
      )}

      {items.map(item => {
        return (
          <tr key={item.id}>
            {beforeColumns && beforeColumns(item)}

            {getTypedObjectKeys(columnTitles).map(key => {
              return (
                <td key={key} className={visibleColumns[key] ? '' : 'hidden-column'}>
                  <div className='table-column__name'>{columnTitles[key]}:</div>

                  {renderItem(key, item)}
                </td>
              );
            })}

            {afterColumns && afterColumns(item)}
          </tr>
        );
      })}
    </tbody>
  );
};
