interface IProps {
  text: string;
  size?: number;
  separator?: string;
}

export const reduceString = ({ text, size = 4, separator = '...' }: IProps) => {
  if (text.length <= size * 2) {
    return text;
  }

  const start = text.slice(0, size);
  const end = text.slice(-size);

  return `${start}${separator}${end}`;
};
