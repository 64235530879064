import { usePageTitle } from '@app/hooks/usePageTitle';
import RequestsWidget from '@widgets/requests';

const RequestsDepositsPage = () => {
  usePageTitle('Deposit requests');

  return <RequestsWidget type='deposit' />;
};

export default RequestsDepositsPage;
