const Forbidden = () => {
  return (
    <div className='forbidden-page'>
      <h1>403</h1>
      <h2>Forbidden</h2>
      <p>Sorry, you don&apos;t have access to this page.</p>
    </div>
  );
};

export default Forbidden;
