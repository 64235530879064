import { useState, useEffect } from 'react';

const useDevice = () => {
  const [device, setDevice] = useState('web');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    setDevice(isMobile ? 'mob' : 'web');
  }, []);

  return device;
};

export default useDevice;
