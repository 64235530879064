import { useState, useEffect } from 'react';

interface IProps {
  storageKey: string;
}

export const useRefetchAuto = ({ storageKey }: IProps) => {
  const getInitialInterval = () => {
    const interval = localStorage.getItem(storageKey);
    return interval ? interval : 'off';
  };

  const [refreshInterval, setRefreshInterval] = useState(getInitialInterval);

  useEffect(() => {
    localStorage.setItem(storageKey, refreshInterval);
  }, [storageKey, refreshInterval]);

  return {
    refreshInterval,
    setRefreshInterval,
  };
};
