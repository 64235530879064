import { useState, useEffect } from 'react';

import { useUserRole } from '@entities/user';

export const useRedirectUrl = () => {
  const [redirectUrl, setRedirectUrl] = useState('');

  const userRole = useUserRole();

  useEffect(() => {
    if (!userRole) {
      setRedirectUrl('');
      return;
    }

    if (userRole) {
      switch (userRole) {
        case 'admin':
        case 'merchant':
          setRedirectUrl('/dashboard');
          break;

        case 'operator':
        case 'provider':
          setRedirectUrl('/requests/deposit');
          break;

        default:
          setRedirectUrl('');
          break;
      }
    }
  }, [userRole]);

  return { redirectUrl };
};
