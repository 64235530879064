import type { TOrderType, TOrderTableColumns } from '@entities/orders';
import RefetchBtn from '@features/refetch-btn';
import { TableColumnsVisibility } from '@features/table-columns-visibility';
import THeadToggler from '@features/table-thead-toggler';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import {
  Table,
  THead,
  TBody,
  TableActions,
  TableActionsItem,
  TableActionsItemCell,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { BulkOrders } from './components/BulkOrders';
import { getMutationHook } from './settings/getMutationHook';
import { useTableSettings } from './settings/useTableSettings';

interface IProps {
  type: TOrderType;
}

const OrdersWidget = ({ type }: IProps) => {
  const useMutationHook = getMutationHook(type);

  const {
    orders,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useMutationHook();

  const {
    columnTitles,
    renderFilterElement,
    getTheadBefore,
    getTheadAfter,
    getTbodyBefore,
    getTbodyAfter,
    renderRow,
  } = useTableSettings({
    type,
    refetch,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (orders) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<TOrderTableColumns> columnTitles={columnTitles} {...filter}>
          <TableActions>
            <TableActionsItem>
              <TableActionsItemCell>
                <RefetchBtn refetch={refetch} />
              </TableActionsItemCell>
            </TableActionsItem>
            <TableActionsItem>
              <BulkOrders />
            </TableActionsItem>
          </TableActions>

          <TableColumnsVisibility storageKey={`order-${type}`} />
          <THeadToggler />

          <TableContainer>
            <Table>
              <THead
                items={orders.data}
                renderItem={renderFilterElement}
                beforeColumns={getTheadBefore}
                afterColumns={getTheadAfter}
              />
              <TBody
                items={orders.data}
                renderItem={renderRow}
                beforeColumns={getTbodyBefore}
                afterColumns={getTbodyAfter}
              />
            </Table>

            <Pagination data={orders.meta} />
            <PaginationRows />
          </TableContainer>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default OrdersWidget;
