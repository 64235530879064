import { Link } from 'react-router-dom';

const LoginBtn = () => {
  return (
    <Link to='/login' className='btn'>
      Log In
    </Link>
  );
};

export default LoginBtn;
