import { z } from 'zod';

export const ZIFSCSchema = z.object({
  id: z.union([z.string(), z.number()]),
  bank_name: z.string().nullable(),
  ifsk: z.string().nullable(),
  branch: z.string().nullable(),
  centre: z.string().nullable(),
  district: z.string().nullable(),
  state: z.string().nullable(),
  city: z.string().nullable(),
  address: z.string().nullable(),
  contact: z.string().nullable(),
  micr: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
});

export type ZIFSC = z.infer<typeof ZIFSCSchema>;
export const ZIFSCResponseSchema = z.array(ZIFSCSchema);
