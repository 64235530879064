import { baseApi } from '@app/store/index.api';

import {
  IMethodResponse,
  INewMethodResponse,
  IEditMethodResponse,
  IEditMethodQuery,
} from '../model/types';

const methodApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMethods: builder.query<IMethodResponse, string>({
      query: query => {
        return {
          url: `methods?${query}`,
        };
      },
    }),
    createMethod: builder.mutation<INewMethodResponse, FormData>({
      query: query => {
        return {
          url: 'methods',
          method: 'POST',
          body: query,
        };
      },
    }),
    editMethod: builder.mutation<IEditMethodResponse, IEditMethodQuery>({
      query: query => {
        return {
          url: `/methods/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
  }),
});

export const { useGetMethodsQuery, useCreateMethodMutation, useEditMethodMutation } = methodApi;
