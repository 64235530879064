import { FC, useState, useEffect, ChangeEvent } from 'react';

import { useToggleActiveStatusMutation } from '@entities/merchant/ip';
import type { IIPWhitelist } from '@entities/merchant/ip';
import { Switch } from '@mui/material';
import { FormItemWithError } from '@shared/components/Form';

interface IProps {
  ip: IIPWhitelist;
  refetch: () => void;
}

export const StatusToggler: FC<IProps> = ({ ip, refetch }) => {
  const [toggleActiveStatus, { error, isError, isLoading, isSuccess }] =
    useToggleActiveStatusMutation();

  const [localValue, setLocalValue] = useState<string | null>(ip.status ? 'active' : 'inactive');
  const [pendingValue, setPendingValue] = useState<string | null>(null);

  useEffect(() => {
    if (pendingValue === null) {
      setLocalValue(ip.status ? 'active' : 'inactive');
    }
  }, [ip.status, pendingValue]);

  useEffect(() => {
    if (isSuccess && pendingValue !== null) {
      setLocalValue(pendingValue);
      setPendingValue(null);
      refetch();
    }
  }, [isSuccess, pendingValue, refetch]);

  useEffect(() => {
    if (isError && pendingValue !== null) {
      setPendingValue(null);
    }
  }, [isError, pendingValue]);

  const handleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newValue = checked ? 'active' : 'inactive';

    setPendingValue(newValue);
    toggleActiveStatus({ id: ip.id }).unwrap();
  };

  return (
    <FormItemWithError name={'status'} error={error} isError={isError}>
      <Switch
        checked={localValue === 'active'}
        onChange={handleChange}
        disabled={isLoading || pendingValue !== null}
      />
    </FormItemWithError>
  );
};
