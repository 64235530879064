import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsProvidersWidget from '@widgets/settings/providers';

const SettingsProvidersPage = () => {
  usePageTitle('Providers');

  return <SettingsProvidersWidget />;
};

export default SettingsProvidersPage;
