import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetProvidersQuery } from '../api';
import type { IProviderFilter } from '../model/types';

interface IProps {
  name?: string;

  disableUrlFilter?: boolean;
}

export const useProviders = ({ name, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<IProviderFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter } = filter;

  const {
    data: providers,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetProvidersQuery(currentFilter.toString());

  useEffect(() => {
    updateFilter({ 'filter[name]': name });
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    providers,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
