import { useFilter } from '@features/table-filter';

import { useGetCurrencyMethodsQuery } from '../api';
import type { ICurrencyMethodFilter } from '../model/types';

export const useCurrencyMethods = () => {
  const filter = useFilter<ICurrencyMethodFilter>();

  const {
    data: currencyMethods,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetCurrencyMethodsQuery(filter.currentFilter.toString());

  return {
    currencyMethods,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
