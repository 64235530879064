import { IDashboard } from '@entities/dashboard';
import { calculateFeesPercentage, calculatePercentage } from '@shared/utils/calculatePercentage';
import { formatAmount } from '@shared/utils/formatAmount';

const chartColors = {
  deposits: '#8B008B',
  withdrawals: '#4e97fd',
  chargebacks: '#e94560',
  refunds: 'orange',
  settlements: '#33d067',
};

export const getDashboardItems = (dashboard: IDashboard) => {
  const { deposits, withdrawals, chargebacks, refunds, settlements, rollingReserves, hold } =
    dashboard;

  const withdrawalsPercent = calculatePercentage(withdrawals.amount, deposits.amount, 0);
  const chargebacksPercent = calculatePercentage(chargebacks.amount, deposits.amount);
  const refundsPercent = calculatePercentage(refunds.amount, deposits.amount);
  const settlementsPercent = calculatePercentage(settlements.amount, deposits.amount, 0);

  const totalFees =
    Number(deposits.amountFee) +
    Number(withdrawals.amountFee) +
    Number(chargebacks.amountFee) +
    Number(refunds.amountFee) +
    Number(settlements.amountFee);

  const depositFeesPercent = calculateFeesPercentage(deposits.amountFee, totalFees);
  const withdrawalFeesPercent = calculateFeesPercentage(withdrawals.amountFee, totalFees);
  const chargebackFeesPercent = calculateFeesPercentage(chargebacks.amountFee, totalFees);
  const refundFeesPercent = calculateFeesPercentage(refunds.amountFee, totalFees);
  const settlementFeesPercent = calculateFeesPercentage(settlements.amountFee, totalFees);

  const dashboardItems = [
    {
      title: 'Deposits',
      amount: deposits.amount,
      countContent: () => formatAmount(deposits.count),
      tooltip: 'Total succesful deposits amount and count for the chosen period.',
    },
    {
      title: 'Withdrawals',
      amount: withdrawals.amount,
      countContent: () => formatAmount(withdrawals.count),
      chartPercent: withdrawalsPercent,
      chartFillColor: chartColors.withdrawals,
      tooltip:
        'Total succesful withdrawals amount and count for the chosen period, and withdrawals rate by amount.',
    },
    {
      title: 'Chargebacks',
      amount: chargebacks.amount,
      countContent: () => formatAmount(chargebacks.count),
      chartPercent: chargebacksPercent,
      chartFillColor: chartColors.chargebacks,
      tooltip:
        'Total chargebacks amount and count for the chosen period, and chargeback rate by amount.',
    },
    {
      title: 'Refunds',
      amount: refunds.amount,
      countContent: () => formatAmount(refunds.count),
      chartPercent: refundsPercent,
      chartFillColor: chartColors.refunds,
      tooltip: 'Total refunds amount and count for the chosen period, and refunds rate by amount.',
    },
    {
      title: 'Settlements',
      amount: settlements.amount,
      countContent: () => formatAmount(settlements.count),
      chartPercent: settlementsPercent,
      chartFillColor: chartColors.settlements,
      tooltip:
        'Total settlements amount and count for the chosen period, and settlements rate by amount.',
    },
    {
      title: 'Rolling reserves',
      amount: rollingReserves.amountFee,
      countContent: () => `${rollingReserves.fee}% / ${rollingReserves.period}`,
      tooltip: 'Total rolling reserves amount for the chosen period and RR conditions.',
    },
    {
      title: 'Hold',
      amount: hold.amount,
      countContent: () => `T+${formatAmount(hold.period)}`,
      tooltip: 'Total hold amount for the chosen period and hold conditions.',
    },
    {
      title: 'Deposit fees',
      amount: deposits.amountFee,
      countContent: () => `${deposits.fee}%`,
      chartPercent: depositFeesPercent,
      chartFillColor: chartColors.deposits,
      tooltip:
        'Total deposit fees paid for the period, their share in total costs, and deposit fee.',
    },
    {
      title: 'Withdrawal fees',
      amount: withdrawals.amountFee,
      countContent: () => `${withdrawals.fee}%`,
      chartPercent: withdrawalFeesPercent,
      chartFillColor: chartColors.withdrawals,
      tooltip:
        'Total withdrawal fees paid for the period, their share in total costs, and withdrawal fee.',
    },
    {
      title: 'Chargeback fees',
      amount: chargebacks.amountFee,
      countContent: () => `${formatAmount(chargebacks.fee)}`,
      chartPercent: chargebackFeesPercent,
      chartFillColor: chartColors.chargebacks,
      tooltip:
        'Total chargeback fees paid for the period, their share in total costs, and chargeback fee.',
    },
    {
      title: 'Refund fees',
      amount: refunds.amountFee,
      countContent: () => `${formatAmount(refunds.fee)}`,
      chartPercent: refundFeesPercent,
      chartFillColor: chartColors.refunds,
      tooltip: 'Total refund fees paid for the period, their share in total costs, and refund fee.',
    },
    {
      title: 'Settlement fees',
      amount: settlements.amountFee,
      countContent: () => `${settlements.fee}%`,
      chartPercent: settlementFeesPercent,
      chartFillColor: chartColors.settlements,
      tooltip:
        'Total settlement fees paid for the period, their share in total costs, and settlement fee.',
    },
  ];

  return {
    dashboardItems,
  };
};
