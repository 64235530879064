import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import useActions from '@app/store/hooks/useActions';
import { useCurrentUser } from '@entities/user';

const ProfileNav = () => {
  const [isOpenNav, setOpenNav] = useState(false);
  const { clearToken, clearCurrentUser, setPageTitle } = useActions();
  const user = useCurrentUser();

  const handleLogout = () => {
    clearCurrentUser();
    clearToken();

    setPageTitle('');
    setOpenNav(false);
  };

  return (
    <div className={`account-block ${isOpenNav ? 'is-visible' : ''}`}>
      <div
        className='account-pic'
        onClick={() => setOpenNav(true)}
        onKeyDown={() => setOpenNav(true)}
        role='button'
        tabIndex={0}
      ></div>
      <div className='account-popup'>
        <div className='account-name'>{user?.currentUser?.name || 'Unknown'}</div>
        <div className='account-email'>{user?.currentUser?.email || 'Unknown'}</div>
        <div className='account-nav'>
          <ul>
            <li>
              <NavLink to='/profile-settings'>Settings</NavLink>
            </li>
            <li className='account-logout-li'>
              <div
                className='account-logout'
                onClick={handleLogout}
                role='button'
                tabIndex={0}
                onKeyDown={handleLogout}
              >
                Logout
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className='account-popup-overlay'
        onClick={() => setOpenNav(false)}
        onKeyDown={() => setOpenNav(false)}
        role='button'
        tabIndex={0}
      ></div>
    </div>
  );
};

export default ProfileNav;
