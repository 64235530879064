import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../settings/base-url';

interface RootState {
  auth: {
    token: string;
  };
  datetime: {
    timeZone: string;
  };
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/admin`,

  prepareHeaders: (headers, { getState }) => {
    const { auth, datetime } = getState() as RootState;

    if (auth.token) {
      headers.set('authorization', `Bearer ${auth.token}`);
    }

    if (datetime.timeZone) {
      headers.set('X-Timezone', datetime.timeZone);
    }

    return headers;
  },
});

type TBaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const baseQueryWithReauth: TBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  // If the result is Unauthenticated, clear the token and current user
  if (result.error && result.error.status === 401) {
    api.dispatch({ type: 'auth/clearToken' });
    api.dispatch({ type: 'auth/clearCurrentUser' });
  }

  // Handle 500 error
  if (result.error && result.error.status === 500) {
    api.dispatch({ type: 'pageFeatures/setServerError', payload: true });
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,

  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
