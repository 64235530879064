import { usePageTitle } from '@app/hooks/usePageTitle';
import { AnalyticsMetabaseWidget } from '@widgets/analytics-metabase';

const AnalyticsMetabasePage = () => {
  usePageTitle('Analytics');

  return <AnalyticsMetabaseWidget />;
};

export default AnalyticsMetabasePage;
