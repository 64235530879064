import { z } from 'zod';

export const ZIPWhitelistSchema = z.object({
  id: z.string(),
  ip: z.string(),
  status: z.union([z.literal(true), z.literal(false)]),
  created_at: z.string(),
  merchant: z.object({
    id: z.string(),
    name: z.string(),
  }),
});

export type ZIPWhitelist = z.infer<typeof ZIPWhitelistSchema>;
export const ZIPWhitelistResponseSchema = z.array(ZIPWhitelistSchema);
