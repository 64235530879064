import { baseApi } from '@app/store/index.api';

import {
  ICurrencyMethodResponse,
  INewCurrencyMethodResponse,
  IEditCurrencyMethodQuery,
  IEditCurrencyMethodResponse,
} from '../model/types';

const currencyMethodApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCurrencyMethods: builder.query<ICurrencyMethodResponse, string>({
      query: query => {
        return {
          url: `currency-methods?${query}`,
        };
      },
    }),
    createCurrencyMethod: builder.mutation<INewCurrencyMethodResponse, FormData>({
      query: query => {
        return {
          url: 'currency-methods',
          method: 'POST',
          body: query,
        };
      },
    }),
    editCurrencyMethod: builder.mutation<IEditCurrencyMethodResponse, IEditCurrencyMethodQuery>({
      query: query => {
        return {
          url: `currency-methods/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
  }),
});

export const {
  useGetCurrencyMethodsQuery,
  useCreateCurrencyMethodMutation,
  useEditCurrencyMethodMutation,
} = currencyMethodApi;
