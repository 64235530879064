const AccessDenied = () => {
  return (
    <div className='not-found'>
      <h2>Access Denied</h2>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default AccessDenied;
