import { baseApi } from '@app/store/index.api';

import {
  IDeleteBankQuery,
  IDeleteBankResponse,
  IEditBankQuery,
  IEditBankResponse,
  IBankResponse,
  INewBankResponse,
} from '../model/types';

const bankApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getBanks: builder.query<IBankResponse, string>({
      query: query => {
        return {
          url: `bank?${query}`,
        };
      },
    }),
    createBank: builder.mutation<INewBankResponse, FormData>({
      query: query => {
        return {
          url: 'bank',
          method: 'POST',
          body: query,
        };
      },
    }),
    editBank: builder.mutation<IEditBankResponse, IEditBankQuery>({
      query: query => {
        return {
          url: `bank/${query.id}`,
          method: 'PUT',
          body: query,
        };
      },
    }),
    deleteBank: builder.mutation<IDeleteBankResponse, IDeleteBankQuery>({
      query: query => {
        return {
          url: `bank/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetBanksQuery,
  useCreateBankMutation,
  useEditBankMutation,
  useDeleteBankMutation,
} = bankApi;
