import { useEffect } from 'react';

import { useLazyDashboardQuery } from '@entities/dashboard';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';

import DashboardItem from './DashboardItem';
import DashboardItemMain from './DashboardItemMain';
import { useDashboardContext } from '../context';
import { getDashboardItems } from '../utils/getDashboardItems';

const DashboardList = () => {
  const [getDashboard, { data: dashboard, isError, isLoading, error, isFetching }] =
    useLazyDashboardQuery();

  const { currentFilter, canFetchDashboard, setFilterError, setCanFetchDashboard } =
    useDashboardContext();

  useEffect(() => {
    if (canFetchDashboard) {
      getDashboard(currentFilter.toString());
    }

    return () => setCanFetchDashboard(false);
  }, [canFetchDashboard, currentFilter, setCanFetchDashboard, getDashboard]);

  useEffect(() => {
    setFilterError(error);
  }, [error, setFilterError]);

  if (isLoading) return <LoadingSpinner />;
  if (isError) return null;

  if (dashboard) {
    const { dashboardItems } = getDashboardItems(dashboard.data);
    const { runningAmount, availableAmount } = dashboard.data;

    return (
      <>
        {isFetching && <Loading />}

        <div className='dashboard-list'>
          <DashboardItemMain runningAmount={runningAmount} availableAmount={availableAmount} />

          {dashboardItems.map(item => (
            <DashboardItem
              key={item.title}
              title={item.title}
              amount={item.amount}
              tooltip={item.tooltip}
              countContent={item.countContent}
              chartPercent={item.chartPercent}
              chartFillColor={item.chartFillColor}
            />
          ))}
        </div>
      </>
    );
  }

  return null;
};

export default DashboardList;
