import {
  IBank,
  IBankTableColumns,
  INewBankQuery,
  useCreateBankMutation,
  useDeleteBankMutation,
  useEditBankMutation,
} from '@entities/bank';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteBank] = useDeleteBankMutation();

  // THead settings
  const columnTitles: IBankTableColumns = {
    name: 'Name',
    holder_name: 'Holder Name',
    bank_validator_name: 'Validator',
    created_at: 'Created At',
    updated_at: 'Updated At',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IBankTableColumns, bank: IBank) => {
    switch (key) {
      case 'actions': {
        const excludeKeys: (keyof IBank)[] = ['id', 'created_at', 'updated_at', 'deleted_at'];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Bank'
                entity={bank}
                excludeKeys={excludeKeys}
                useEditEntityMutation={useEditBankMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: <ModalViewEntity entity={bank} title='View Bank' />,
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={bank.id}
                message='Do you want to delete this bank?'
                deleteEntity={deleteBank}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return bank[key];
    }
  };

  // New bank settings
  const newBankKeys: (keyof INewBankQuery)[] = [
    'name',
    'transaction_validator_name',
    'holder_name',
  ];
  const NewBank = () => (
    <ModalNewEntity
      title='New Bank'
      entityKeys={newBankKeys}
      useCreateEntityMutation={useCreateBankMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewBank,
  };
};
