import type { TRequestType, TRequestTableColumns } from '@entities/requests';
import { RefetchAuto, useRefetchAuto } from '@features/refetch-auto';
import RefetchBtn from '@features/refetch-btn';
import { TableColumnsVisibility } from '@features/table-columns-visibility';
import THeadToggler from '@features/table-thead-toggler';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import {
  TBody,
  THead,
  Table,
  TableActions,
  TableActionsItem,
  TableActionsItemCell,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { BulkRequests } from './components/BulkRequests';
import { getMutationHook } from './settings/getMutationHook';
import { useTableSettings } from './settings/useTableSettings';

interface IProps {
  type: TRequestType;
}

const RequestsWidget = ({ type }: IProps) => {
  const useMutationHook = getMutationHook(type);
  const { refreshInterval, setRefreshInterval } = useRefetchAuto({
    storageKey: `refetch-auto-${type}`,
  });

  const {
    requests,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useMutationHook({ refreshInterval });

  const {
    columnTitles,
    hiddenColumnKeys,
    renderFilterElement,
    getTheadBefore,
    getTheadAfter,
    getTbodyBefore,
    getTbodyAfter,
    renderRow,
  } = useTableSettings({
    type,
    refetch,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (requests) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<TRequestTableColumns>
          columnTitles={columnTitles}
          hiddenColumnKeys={hiddenColumnKeys}
          {...filter}
        >
          <TableActions>
            <TableActionsItem>
              <TableActionsItemCell>
                <RefetchBtn refetch={refetch} />
                <RefetchAuto
                  refreshInterval={refreshInterval}
                  onChangeInterval={setRefreshInterval}
                />
              </TableActionsItemCell>
            </TableActionsItem>
            <TableActionsItem>
              <BulkRequests refetch={refetch} />
            </TableActionsItem>
          </TableActions>

          <TableColumnsVisibility storageKey={`request-${type}`} />
          <THeadToggler />

          <TableContainer>
            <Table>
              <THead
                items={requests.data}
                renderItem={renderFilterElement}
                beforeColumns={getTheadBefore}
                afterColumns={getTheadAfter}
              />
              <TBody
                items={requests.data}
                renderItem={renderRow}
                beforeColumns={getTbodyBefore}
                afterColumns={getTbodyAfter}
              />
            </Table>

            <Pagination data={requests.meta} />
            <PaginationRows />
          </TableContainer>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default RequestsWidget;
