import { FC, PropsWithChildren } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IZodValidationError } from '@shared/zod';

import { useTypedFormError } from '../hooks/useTypedFormError';

export type IFormError = FetchBaseQueryError | SerializedError | IZodValidationError | undefined;

interface IProps {
  name: string;
  secondName?: string;
  label?: string;
  isError: boolean;
  error: IFormError;
}

export const FormItemWithError: FC<PropsWithChildren<IProps>> = ({
  name,
  secondName,
  label,
  children,
  isError,
  error,
}) => {
  const { renderErrorMessage, hasErrorForKey } = useTypedFormError(isError, error);

  const generalErrorMessage = renderErrorMessage('general');
  const nameErrorMessage = renderErrorMessage(name) || renderErrorMessage(secondName);
  const hasGeneralError = hasErrorForKey('general');
  const hasNameError = hasErrorForKey(name) || hasErrorForKey(secondName);

  return (
    <div className={`form-item ${hasNameError || hasGeneralError ? 'has-error' : ''}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className='form-field'>{children}</div>
      <div className='form-error'>{hasNameError ? nameErrorMessage : generalErrorMessage}</div>
    </div>
  );
};
