import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsCurrenciesWidget from '@widgets/settings/currencies';

const SettingsCurrenciesPage = () => {
  usePageTitle('Currencies');

  return <SettingsCurrenciesWidget />;
};

export default SettingsCurrenciesPage;
