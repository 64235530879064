import { TOrder, TOrderType, TOrderTableColumns } from '@entities/orders';
import { IOrderDepositTableColumns } from '@entities/orders/deposit';
import { IOrderWithdrawalTableColumns } from '@entities/orders/withdrawal';
import { TUserRoles, useUserRole } from '@entities/user';
import { ModalViewEntity } from '@features/modal-entity-view';
import ReduceAndCopyString from '@features/reduce-and-copy-string';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import TableCheckAllRows from '@features/table-check-all-rows';
import TableCheckOneRow from '@features/table-check-one-row';
import { FilterDateRange, FilterInput, FilterSelect, ResetFilterBtn } from '@features/table-filter';
import { getSelectOptions } from '@shared/components/Form';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';
import { formatAmount } from '@shared/utils/formatAmount';
import { removeKeysFromObject } from '@shared/utils/removeKeysFromObject';

import { ApproveOrder } from '../components/ApproveOrder';

interface IProps {
  type: TOrderType;
  refetch?: () => void;
}

export const useTableSettings = ({ type, refetch }: IProps) => {
  const userRole = useUserRole();

  // THead settings
  const depositColumnTitles: IOrderDepositTableColumns = {
    id: 'Order ID',
    human_hash: 'Short ID',
    request_id: 'Request ID',
    requested_amount: 'Requested amount',
    actual_amount: 'Actual amount',
    method: 'Method',
    merchant_short_name: 'Merchant short name',
    customer_transaction_id: 'UTR',
    created_at: 'Create date',
    updated_at: 'Update date',
    merchant_order_id: 'Merchant order ID',
    merchant_customer_id: 'Merchant customer ID',
    status: 'Status',
    status_reason: 'Status reason',
  };

  const withdrawalColumnTitles: IOrderWithdrawalTableColumns = {
    id: 'Order ID',
    human_hash: 'Short ID',
    request_id: 'Request ID',
    customer_account_name: 'Beneficiary Name',
    customer_bank_account: 'Account Number',
    customer_bank_code: 'IFSC Code',
    requested_amount: 'Requested amount',
    actual_amount: 'Actual amount',
    method: 'Method',
    merchant_short_name: 'Merchant short name',
    customer_transaction_id: 'UTR',
    created_at: 'Create date',
    updated_at: 'Update date',
    merchant_order_id: 'Merchant order ID',
    merchant_customer_id: 'Merchant customer ID',
    status: 'Status',
    status_reason: 'Status reason',
  };

  type TColumnTitles = {
    [role in TOrderType]: {
      [type in TUserRoles]:
        | Partial<IOrderDepositTableColumns>
        | Partial<IOrderWithdrawalTableColumns>;
    };
  };
  const getColumnTitles = (type: TOrderType, userRole: TUserRoles) => {
    if (!type || !userRole) return {} as TOrderTableColumns;

    const columnTitles: TColumnTitles = {
      deposit: {
        admin: depositColumnTitles,
        merchant: removeKeysFromObject(depositColumnTitles, [
          'request_id',
          'human_hash',
          'status_reason',
        ]),
        operator: depositColumnTitles,
        provider: depositColumnTitles,
        user: depositColumnTitles,
      },
      withdrawal: {
        admin: withdrawalColumnTitles,
        merchant: removeKeysFromObject(withdrawalColumnTitles, [
          'request_id',
          'human_hash',
          'status_reason',
        ]),
        operator: withdrawalColumnTitles,
        provider: withdrawalColumnTitles,
        user: withdrawalColumnTitles,
      },
    };

    return columnTitles[type][userRole] as TOrderTableColumns;
  };

  const filterStatuses =
    type === 'deposit'
      ? ['pending', 'new', 'chargeback', 'success', 'failed', 'expired', 'cancelled', 'refunded']
      : ['pending', 'new', 'success', 'failed'];

  const renderFilterElement = (key: keyof TOrder) => {
    switch (key) {
      case 'status':
        return <FilterSelect name={key} options={getSelectOptions(filterStatuses)} />;

      case 'created_at':
      case 'updated_at':
        return <FilterDateRange name={key} />;

      default:
        return <FilterInput name={key} />;
    }
  };

  const getTheadBefore = (orders: TOrder[]) => (
    <th className='hidden-on-mob'>
      <TableCheckAllRows items={orders} />
    </th>
  );
  const getTheadAfter = (orders: TOrder[]) => (
    <th>
      <ResetFilterBtn />
    </th>
  );

  // TBody settings

  const renderRow = (key: keyof TOrderTableColumns, order: TOrder) => {
    switch (key) {
      case 'id':
      case 'request_id':
        return <ReduceAndCopyString text={order[key]} />;

      case 'customer_transaction_id':
        return <ReduceAndCopyString text={order[key]} needReduce={false} />;

      case 'actual_amount':
      case 'requested_amount':
        return order[key] && `${formatAmount(String(order[key]))} ${order.currency}`;

      case 'status':
        return <RequestStatusLabel status={String(order[key])} />;

      default:
        return order[key];
    }
  };

  const getTbodyBefore = (order: TOrder) => {
    return (
      <td>
        <TableCheckOneRow id={order.id} isDisabled={false} />
      </td>
    );
  };
  const getTbodyAfter = (order: TOrder) => {
    const renamedLabels: Partial<TOrder> = {
      human_hash: 'Short ID',
      customer_transaction_id: 'UTR',
    };
    const actionBtnsConfig: ITableActionsConfig = {
      view: {
        isEnabled: true,
        isVisible: true,
        popupElem: (
          <ModalViewEntity entity={order} title='View Order' renamedLabels={renamedLabels} />
        ),
        popupSize: 'md',
      },
    };

    if (type === 'deposit') {
      actionBtnsConfig.approve = {
        isEnabled: !order.request_id,
        isVisible: true,
        popupElem: <ApproveOrder order={order} refetch={refetch} />,
        popupSize: 'sm',
        allowedRoles: ['admin', 'operator'],
      };
    }

    return (
      <td className='action-btns-row'>
        <TableActionBtns config={actionBtnsConfig} />
      </td>
    );
  };

  return {
    columnTitles: getColumnTitles(type, userRole),

    renderFilterElement,
    getTheadBefore,
    getTheadAfter,

    renderRow,
    getTbodyBefore,
    getTbodyAfter,
  };
};
