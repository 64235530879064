import { FormEvent, useState } from 'react';

import { useFee } from '@entities/orders/transaction';
import { BanksSelect } from '@features/banks';
import { CurrenciesSelect } from '@features/currencies';
import { MerchantsSelect } from '@features/merchants';
import { MethodsSelect } from '@features/methods';
import { MIDsSelect } from '@features/mids';
import { ProvidersSelect } from '@features/providers';
import { TrafficsSelect } from '@features/traffic';
import { FormItemWithError } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { TUseMutationPost } from '@shared/types/fetch-data';
import { excludeComponentProps } from '@shared/utils/excludeComponentProps';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T, TResponse> {
  entityKeys: (keyof T)[];
  excludeFilterKeys?: string[];
  title: string;
  secondErrorNames?: Partial<Record<keyof T, string>>[];
  useCreateEntityMutation: TUseMutationPost<TResponse>;
  onTransformFormData?: (data: FormData) => FormData;
  refetch: () => void;
  otherProps?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ModalNewEntity = <T, TResponse>({
  entityKeys,
  excludeFilterKeys = [],
  title,
  secondErrorNames = [],
  useCreateEntityMutation,
  onTransformFormData,
  refetch,
  otherProps,
}: IProps<T, TResponse>) => {
  const [merchantId, setMerchantId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [midId, setMidId] = useState('');

  const { setAmount, setExchangeRate, fee, usdt_amount } = useFee({
    mid_id: midId,
  });

  const { closeModal } = useModal();

  const [createEntity, { isLoading, isError, error, isSuccess }] = useCreateEntityMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const transformedFormData = onTransformFormData ? onTransformFormData(formData) : formData;

    try {
      const result = await createEntity(transformedFormData);

      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasSecondErrorName = (name: keyof T) => {
    return secondErrorNames.some(item => item[name]);
  };

  const getSecondErrorName = (name: keyof T) => {
    return secondErrorNames.find(item => item[name])[name];
  };

  const renderItem = (key: keyof T) => {
    const keyString = String(key);

    switch (key) {
      case 'description':
        return <textarea name={keyString} />;

      case 'email':
        return <input type='email' name={keyString} />;

      case 'logo':
        return <input type='file' name={keyString} />;

      case 'file':
        return <input type='file' name={keyString} accept='.csv' />;

      case 'login_credentials_json':
        return <textarea name={keyString} />;

      case 'merchant_id':
        return <MerchantsSelect onChangeMerchant={setMerchantId} />;

      case 'mid_id':
        return <MIDsSelect merchantIds={merchantId ? [merchantId] : []} onChangeMID={setMidId} />;

      case 'provider_id':
        return <ProvidersSelect />;

      case 'bank_id':
        return <BanksSelect />;

      case 'type':
        return <input type='text' name={keyString} defaultValue={otherProps?.type} readOnly />;

      case 'traffic':
        return <TrafficsSelect />;

      case 'currency_id':
      case 'currencies.0': {
        const neededProps = { merchantId };
        const props = excludeComponentProps(neededProps, excludeFilterKeys);

        return <CurrenciesSelect {...props} name={keyString} onChangeCurrency={setCurrencyId} />;
      }

      case 'method_id': {
        const neededProps = { merchantId, currencyId };
        const props = excludeComponentProps(neededProps, excludeFilterKeys);

        return <MethodsSelect {...props} />;
      }

      // create settlement
      case 'fee':
        return <input type='text' name={keyString} value={fee} readOnly />;
      case 'usdt_amount':
        return <input type='text' name={keyString} value={usdt_amount} readOnly />;
      case 'amount':
        return <input type='text' name={keyString} onBlur={e => setAmount(e.target.value)} />;
      case 'exchange_rate':
        return <input type='text' name={keyString} onBlur={e => setExchangeRate(e.target.value)} />;

      default:
        return <input type='text' name={keyString} />;
    }
  };

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form' onSubmit={handleSubmit}>
        {entityKeys.map(key => {
          const keyString = String(key);
          const label = `${transformObjectKeyToTitle(keyString)}:`;

          const secondErrorNameProps = hasSecondErrorName(key)
            ? { secondName: getSecondErrorName(key) }
            : {};

          return (
            <FormItemWithError
              key={keyString}
              name={keyString}
              {...secondErrorNameProps}
              label={label}
              isError={isError}
              error={error}
            >
              {renderItem(key)}
            </FormItemWithError>
          );
        })}
        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Create
          </button>
        </div>
      </form>
    </>
  );
};
