import { baseApi } from '@app/store/index.api';

import {
  IDeleteMIDQuery,
  IDeleteMIDResponse,
  IEditMIDQuery,
  IEditMIDResponse,
  IMIDResponse,
  INewMIDResponse,
  IMIDSettingsQuery,
  IMIDSettingsResponse,
  IEditMIDSettingsQuery,
  IEditMIDSettingsResponse,
} from '../model/types';

const midApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMIDs: builder.query<IMIDResponse, string>({
      query: query => {
        return {
          url: `mids?${query}`,
        };
      },
    }),
    createMID: builder.mutation<INewMIDResponse, FormData>({
      query: query => {
        return {
          url: 'mids',
          method: 'POST',
          body: query,
        };
      },
    }),
    editMID: builder.mutation<IEditMIDResponse, IEditMIDQuery>({
      query: query => {
        return {
          url: `mids/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
    deleteMID: builder.mutation<IDeleteMIDResponse, IDeleteMIDQuery>({
      query: query => {
        return {
          url: `mids/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
    getMIDSettings: builder.query<IMIDSettingsResponse, IMIDSettingsQuery>({
      query: query => {
        return {
          url: `mids/${query.id}/settings`,
        };
      },
    }),
    editMIDSetting: builder.mutation<IEditMIDSettingsResponse, IEditMIDSettingsQuery>({
      query: ({ id, ...body }) => {
        return {
          url: `mids/${id}/settings`,
          method: 'PATCH',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetMIDsQuery,
  useCreateMIDMutation,
  useEditMIDMutation,
  useDeleteMIDMutation,
  useGetMIDSettingsQuery,
  useEditMIDSettingMutation,
} = midApi;
