import { TTableColumnTitles, TTableColumnsVisibility } from '@shared/components/Table';
import { getTypedObjectKeys } from '@shared/types/typedObjectEntries';

export const generateDefaultVisibleColumns = <T>(
  columnTitles: TTableColumnTitles<T>,
  hiddenColumnKeys: (keyof TTableColumnTitles<T>)[] = [],
) => {
  return getTypedObjectKeys(columnTitles).reduce((visibleColumns, key) => {
    visibleColumns[key] = !hiddenColumnKeys.includes(key);

    return visibleColumns;
  }, {} as TTableColumnsVisibility<T>);
};
