import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsIFSCWidget from '@widgets/settings/ifsc';

const SettingsIFSCPage = () => {
  usePageTitle('IFSC codes');

  return <SettingsIFSCWidget />;
};

export default SettingsIFSCPage;
