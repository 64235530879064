import { usePageTitle } from '@app/hooks/usePageTitle';
import ProfileSettingsWidget from '@widgets/profile/settings';

const ProfileSettingsPage = () => {
  usePageTitle('Profile Settings');

  return <ProfileSettingsWidget />;
};

export default ProfileSettingsPage;
