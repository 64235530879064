import { FC } from 'react';

import { withRoleAccess } from '@app/routes';
import { TWalletType } from '@entities/wallets';
import type { IWalletsMonitoringTableColumns } from '@entities/wallets/monitoring';
import { useWalletsMonitoring } from '@entities/wallets/monitoring';
import ModalOpenBtn from '@features/modal-open-btn';
import { RefetchAuto, useRefetchAuto } from '@features/refetch-auto';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import {
  Table,
  THead,
  TBody,
  TableActions,
  TableActionsItem,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { useTableSettings } from './settings/useTableSettings';

import './monitoring.css';

interface IProps {
  type: TWalletType;
}

const WalletsMonitoringWidget: FC<IProps> = ({ type }) => {
  const { refreshInterval, setRefreshInterval } = useRefetchAuto({
    storageKey: `refetch-auto-wallet-${type}`,
  });

  const {
    walletsMonitoring,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useWalletsMonitoring({ type, refreshInterval });

  const { columnTitles, renderFilterElement, renderRow, NewWallet } = useTableSettings({
    type,
    refetch,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (walletsMonitoring) {
    const NewWalletsMonitoringBtn = withRoleAccess(ModalOpenBtn, ['admin', 'operator']);

    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<IWalletsMonitoringTableColumns> columnTitles={columnTitles} {...filter}>
          <TableActions>
            <TableActionsItem>
              <RefetchAuto
                refreshInterval={refreshInterval}
                onChangeInterval={setRefreshInterval}
              />
            </TableActionsItem>
            <TableActionsItem>
              <NewWalletsMonitoringBtn text='New' modalContent={<NewWallet />} />
            </TableActionsItem>
          </TableActions>

          <TableContainer>
            <Table>
              <THead renderItem={renderFilterElement} />
              <TBody items={walletsMonitoring.data} renderItem={renderRow} />
            </Table>

            <Pagination data={walletsMonitoring.meta} />
            <PaginationRows />
          </TableContainer>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default WalletsMonitoringWidget;
