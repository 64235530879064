import { usePageTitle } from '@app/hooks/usePageTitle';
import TransactionsWidget from '@widgets/transactions';

const TransactionsDepositsPage = () => {
  usePageTitle('Deposit Transactions');

  return <TransactionsWidget type='deposit' />;
};

export default TransactionsDepositsPage;
