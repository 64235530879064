import {
  ICurrency,
  ICurrencyTableColumns,
  INewCurrencyQuery,
  useCreateCurrencyMutation,
  useDeleteCurrencyMutation,
  useEditCurrencyMutation,
} from '@entities/currency';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteCurrency] = useDeleteCurrencyMutation();

  // THead settings
  const columnTitles: ICurrencyTableColumns = {
    id: 'ID',
    code: 'Code',
    title: 'Title',
    created_at: 'Created At',
    updated_at: 'Updated At',
    symbol: 'Symbol',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof ICurrencyTableColumns, currency: ICurrency) => {
    switch (key) {
      case 'actions': {
        const excludeKeys: (keyof ICurrency)[] = ['id', 'created_at', 'updated_at', 'deleted_at'];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Currency'
                entity={currency}
                excludeKeys={excludeKeys}
                useEditEntityMutation={useEditCurrencyMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: <ModalViewEntity entity={currency} title='View Currency' />,
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={currency.id}
                message='Do you want to delete this currency?'
                deleteEntity={deleteCurrency}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return currency[key];
    }
  };

  // New merchant settings
  const newCurrencyKeys: (keyof INewCurrencyQuery)[] = ['code', 'title', 'symbol'];
  const NewCurrency = () => (
    <ModalNewEntity
      title='New Currency'
      entityKeys={newCurrencyKeys}
      useCreateEntityMutation={useCreateCurrencyMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewCurrency,
  };
};
