import { FC } from 'react';

import { TRequestType, useViewRequestQuery } from '@entities/requests';
import DataTableView from '@shared/components/DataTableView';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { useModal } from '@shared/components/Modal';
import RequestStatusLabel from '@shared/components/RequestStatusLabel';
import { IObjectDeep } from '@shared/types/fetch-data';

import { ViewParserTask } from './ViewParserTask';

interface IProps {
  type: TRequestType;
  id: string;
}

export const ViewRequest: FC<IProps> = ({ id, type }) => {
  const { openModal } = useModal();
  const { data: requestInfo, isLoading } = useViewRequestQuery({ id });

  const transformValue = (value: IObjectDeep[keyof IObjectDeep]): JSX.Element => {
    if (typeof value === 'string' && value.includes('ParserTask:')) {
      const [message, taskId] = value.split('ParserTask:');

      return (
        <>
          {message} ParserTask: &nbsp;
          <button
            className='link-styled-text'
            onClick={() => openModal(<ViewParserTask id={taskId.trim()} type={type} />, 'lg')}
          >
            {taskId.trim()}
          </button>
        </>
      );
    }

    return <>{value}</>;
  };

  if (isLoading) return <LoadingSpinner />;

  if (requestInfo) {
    const { status, created_at } = requestInfo.data;

    return (
      <div className='request-info'>
        <p>
          <strong>Request ID:</strong> <span>{id}</span>
        </p>
        <p>
          <strong>Status:</strong> <RequestStatusLabel status={status} />
        </p>
        <p>
          <strong>Created at:</strong> {created_at}
        </p>

        <DataTableView data={requestInfo.data} transformValue={transformValue} />
      </div>
    );
  }

  return null;
};
