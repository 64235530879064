import { usePageTitle } from '@app/hooks/usePageTitle';
import DashboardWidget from '@widgets/dashboard';

const DashboardPage = () => {
  usePageTitle('Dashboard');

  return <DashboardWidget />;
};

export default DashboardPage;
