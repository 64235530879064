import { baseApi } from '@app/store/index.api';

import { IMerchantResetPasswordResponse, IMerchantResetPasswordQuery } from '../model/types';

const merchantUserApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    resetMerchantPassword: builder.mutation<
      IMerchantResetPasswordResponse,
      IMerchantResetPasswordQuery
    >({
      query: query => {
        return {
          url: '/merchant/user/reset-password',
          method: 'PATCH',
          body: query,
        };
      },
    }),
  }),
});

export const { useResetMerchantPasswordMutation } = merchantUserApi;
