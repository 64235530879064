import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsMethodsWidget from '@widgets/settings/methods';

const SettingsMethodsPage = () => {
  usePageTitle('Methods');

  return <SettingsMethodsWidget />;
};

export default SettingsMethodsPage;
