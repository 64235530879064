import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import DvrRoundedIcon from '@mui/icons-material/DvrRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

import { IRoute } from '../model/types';

export const routes: IRoute[] = [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: <GridViewRoundedIcon />,
    allowedRoles: ['admin', 'merchant'],
  },
  {
    url: '/analytics',
    name: 'Analytics',
    icon: <AssessmentIcon />,
    allowedRoles: ['admin'],
  },
  {
    url: '/settlements',
    name: 'Settlements',
    icon: <AccountBalanceRoundedIcon />,
    allowedRoles: ['admin', 'merchant'],
  },
  {
    name: 'Orders',
    icon: <LocalMallRoundedIcon />,
    sublinks: [
      {
        url: '/orders/deposit',
        name: 'Deposit',
        allowedRoles: ['admin', 'merchant', 'operator'],
      },
      {
        url: '/orders/withdrawal',
        name: 'Withdrawal',
        allowedRoles: ['admin', 'merchant', 'operator'],
      },
    ],
  },
  {
    url: '/customers',
    name: 'Customers',
    icon: <PeopleOutlineRoundedIcon />,
    isDisabled: true,
    allowedRoles: ['admin'],
  },
  {
    name: 'P2P Requests',
    icon: <DvrRoundedIcon />,
    sublinks: [
      {
        url: '/requests/deposit',
        name: 'Deposit',
        allowedRoles: ['admin', 'operator', 'provider'],
      },
      {
        url: '/requests/withdrawal',
        name: 'Withdrawal',
        allowedRoles: ['admin', 'operator', 'provider'],
      },
    ],
  },
  {
    name: 'Transactions',
    icon: <SwapHorizIcon />,
    sublinks: [
      {
        url: '/transactions/deposit',
        name: 'Deposit',
        allowedRoles: ['admin', 'operator'],
      },
      {
        url: '/transactions/withdrawal',
        name: 'Withdrawal',
        allowedRoles: ['admin', 'operator'],
      },
    ],
  },
  {
    name: 'Wallets',
    icon: <AccountBalanceWalletIcon />,
    sublinks: [
      {
        url: '/wallets/deposit',
        name: 'Deposit',
        allowedRoles: ['admin', 'operator'],
      },
      {
        url: '/wallets/withdrawal',
        name: 'Withdrawal',
        allowedRoles: ['admin', 'operator'],
      },
      {
        url: '/wallets/settlement',
        name: 'Settlement',
        allowedRoles: ['admin'],
      },
    ],
  },
  {
    url: '/logs',
    name: 'Logs',
    icon: <BookRoundedIcon />,
    allowedRoles: ['admin'],
  },
  {
    name: 'P2P setup',
    icon: <DisplaySettingsRoundedIcon />,
    isDisabled: true,
    sublinks: [
      {
        url: '/p2p-forms/forms',
        name: 'Forms',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
      {
        url: '/p2p-forms/deposit-accounts',
        name: 'Deposit accounts',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
      {
        url: '/p2p-forms/withdrawal-accounts',
        name: 'Withdrawal accounts',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
    ],
  },
  {
    name: 'Settings',
    icon: <TuneRoundedIcon />,
    sublinks: [
      {
        url: '/settings/ip_whitelist',
        name: 'IP Whitelist',
        allowedRoles: ['admin', 'merchant'],
      },
      {
        url: '/settings/api',
        name: 'API keys',
        allowedRoles: ['merchant'],
      },
      {
        url: '/settings/config',
        name: 'Config',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/ifsc',
        name: 'IFSC',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/banks',
        name: 'Banks',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/merchants',
        name: 'Merchants',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/providers',
        name: 'Providers',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/mids',
        name: 'MIDs',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/currencies',
        name: 'Currencies',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/methods',
        name: 'Methods',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/currency-methods',
        name: 'Currency Methods',
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/users',
        name: 'Users',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/fees',
        name: 'Fees',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
      {
        url: '/settings/roles',
        name: 'Roles',
        isDisabled: true,
        allowedRoles: ['admin'],
      },
    ],
  },
  {
    url: '/profile-settings',
    name: 'Profile settings',
    isSkipNav: true,
    allowedRoles: ['merchant'],
  },
];
