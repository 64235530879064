import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '@entities/auth';
import { useUserRole, useHasLoggedAs } from '@entities/user';

import AccessDenied from './AccessDenied';
import { findRouteInfo } from '../utils/findRouteInfo';

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const routeInfo = findRouteInfo(location.pathname);
  const userRole = useUserRole();
  const hasLoggedAs = useHasLoggedAs();

  if (!isAuthenticated) {
    return <Navigate to={`/${hasLoggedAs}`} />;
  }

  if (!userRole) {
    return <Outlet />;
  }

  if (!routeInfo || !routeInfo.allowedRoles.includes(userRole)) {
    return <AccessDenied />;
  }

  return <Outlet />;
};

export default PrivateRoute;
