import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsConfigWidget from '@widgets/settings/config';

const SettingsConfigPage = () => {
  usePageTitle('Config');

  return <SettingsConfigWidget />;
};

export default SettingsConfigPage;
