import { useTableContext } from '@shared/components/Table';

export const ResetFilterBtn = <T,>() => {
  const { resetFilter } = useTableContext<T>();

  return (
    <button type='button' className='accent-btn' onClick={resetFilter}>
      Reset
    </button>
  );
};
