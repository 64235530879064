import { usePageTitle } from '@app/hooks/usePageTitle';
import OrdersWidget from '@widgets/orders';

const OrdersDepositsPage = () => {
  usePageTitle('Deposit orders');

  return <OrdersWidget type='deposit' />;
};

export default OrdersDepositsPage;
