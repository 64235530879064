import { TRequestType } from '@entities/requests';
import { useRequestDeposits } from '@entities/requests/deposit';
import { useRequestWithdrawals } from '@entities/requests/withdrawal';

export const getMutationHook = (type: TRequestType) => {
  switch (type) {
    case 'deposit':
      return useRequestDeposits;
    case 'withdrawal':
      return useRequestWithdrawals;
  }
};
