import {
  INewWalletQuery,
  TWalletType,
  useCreateWalletMutation,
  useUpdateWalletMutation,
  walletParserModes,
  walletStatuses,
} from '@entities/wallets';
import { IWalletsMonitoring, IWalletsMonitoringTableColumns } from '@entities/wallets/monitoring';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import { FilterInput, FilterSelect, ResetFilterBtn } from '@features/table-filter';
import { WalletCredentials } from '@features/wallets';

import { ParserModeToggler } from '../components/ParserModeToggler';
import { StatusToggler } from '../components/StatusToggler';

interface IProps {
  type: TWalletType;
  refetch?: () => void;
}

export const useTableSettings = ({ type, refetch }: IProps) => {
  // THead settings
  const columnTitles: IWalletsMonitoringTableColumns = {
    name: 'Name',
    type: 'Type',
    status_toggle: 'Status toggle',
    status: 'Status',
    parser_toggle: 'Parser toggle',
    parser_mode: 'Parser mode',
    bank: 'Bank',
    provider: 'Provider',
    mid: 'MID',
    actions: 'Actions',
  };

  const renderFilterElement = (key: keyof IWalletsMonitoringTableColumns) => {
    switch (key) {
      case 'type':
      case 'status_toggle':
      case 'parser_toggle':
      case 'bank':
        return null;

      case 'status':
        return <FilterSelect name={key} options={walletStatuses} />;

      case 'parser_mode':
        return <FilterSelect name={key} options={walletParserModes} />;

      case 'mid':
        return <FilterInput name={'mid_name'} />;

      case 'actions':
        return <ResetFilterBtn />;

      default:
        return <FilterInput name={key} />;
    }
  };

  // TBody settings

  const renderRow = (key: keyof IWalletsMonitoringTableColumns, monitoring: IWalletsMonitoring) => {
    switch (key) {
      case 'status_toggle':
        return <StatusToggler monitoring={monitoring} refetch={refetch} />;

      case 'parser_toggle':
        return <ParserModeToggler monitoring={monitoring} refetch={refetch} />;

      case 'bank':
        return monitoring.bank.name;

      case 'provider':
        return monitoring.provider.name;

      case 'mid':
        return monitoring.mid.name;

      case 'actions': {
        const excludeKeys: (keyof IWalletsMonitoring)[] = ['id', 'parser_mode', 'type', 'status'];
        const renamedLabels: Partial<IWalletsMonitoring> = {
          provider_id: 'Provider:',
        };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Wallet'
                entity={monitoring}
                excludeKeys={excludeKeys}
                additionalKeys={[{ login_credentials_json: '' }]}
                renamedLabels={renamedLabels}
                useEditEntityMutation={useUpdateWalletMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
            allowedRoles: ['admin', 'merchant', 'operator'],
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                entity={monitoring}
                title='View Monitoring'
                deepEntityValues={{ bank: ['name'] }}
                additionalData={[
                  {
                    label: 'Credentials:',
                    element: <WalletCredentials walletId={monitoring.id} />,
                  },
                ]}
              />
            ),
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return monitoring[key];
    }
  };

  // New monitoring settings
  const newWalletKeys: (keyof INewWalletQuery)[] =
    type === 'settlement'
      ? ['merchant_id', 'mid_id', 'provider_id', 'type']
      : [
          'merchant_id',
          'mid_id',
          'type',
          'provider_id',
          'bank_id',
          'name',
          'wallet_address',
          'holder_name',
          'login_credentials_json',
          'bank_code',
        ];

  const NewWallet = () => (
    <ModalNewEntity
      title='New Wallet'
      entityKeys={newWalletKeys}
      useCreateEntityMutation={useCreateWalletMutation}
      refetch={refetch}
      otherProps={{ type }}
    />
  );

  return {
    columnTitles,
    renderFilterElement,
    renderRow,
    NewWallet,
  };
};
