import DashboardFilter from './components/DashboardFilter';
import DashboardList from './components/DashboardList';
import { DashboardProvider } from './context';

import './dashboard.css';

const DashboardWidget = () => {
  return (
    <DashboardProvider>
      <DashboardFilter />
      <DashboardList />
    </DashboardProvider>
  );
};

export default DashboardWidget;
