import { baseApi } from '@app/store/index.api';

import { ITransactionWithdrawalResponse } from '../model/types';

const transactionWithdrawalApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    transactionWithdrawal: builder.query<ITransactionWithdrawalResponse, string>({
      query: query => {
        return {
          url: `/transactions/withdrawal?${query}`,
        };
      },
    }),
  }),
});

export const { useTransactionWithdrawalQuery } = transactionWithdrawalApi;
