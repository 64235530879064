import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { useModal } from '../context';
import { IModalInstance } from '../model/types';

interface IProps {
  modal: IModalInstance;
}

const Modal: FC<IProps> = ({ modal }) => {
  const { closeModal } = useModal();

  return (
    <div className='modal-content' data-size={modal.size}>
      <button className='modal-close' onClick={closeModal}>
        <CloseIcon />
      </button>
      {modal.content}
    </div>
  );
};

export default Modal;
