export const removeKeysFromObject = <T, K extends keyof T>(
  obj: T,
  keysToRemove: K[],
): { [P in keyof T]: T[P] } => {
  const reducedObject: T = { ...obj };

  keysToRemove.forEach(key => {
    delete reducedObject[key];
  });

  return reducedObject;
};
