import { ReactNode } from 'react';

import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';

import { useTableContext } from '../context';

interface IProps<T> {
  items?: T[];
  renderItem?: (key: keyof T) => ReactNode;
  beforeColumns?: (items: T[]) => ReactNode;
  afterColumns?: (items: T[]) => ReactNode;
}

export const THead = <T,>({ items, renderItem, beforeColumns, afterColumns }: IProps<T>) => {
  const { isTheadVisible, columnTitles, visibleColumns } = useTableContext<T>();

  return (
    <thead className={isTheadVisible ? 'thead-visible' : 'thead-hidden'}>
      <tr>
        {beforeColumns && beforeColumns(items)}

        {getTypedObjectEntries(columnTitles).map(([key, name]) => {
          const isVisible = visibleColumns[key];

          return (
            <th className={isVisible ? '' : 'hidden-column'} key={String(key)}>
              <span className='table-title'>{name}</span>

              {renderItem && renderItem(key)}
            </th>
          );
        })}

        {afterColumns && afterColumns(items)}
      </tr>
    </thead>
  );
};
