import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import FaviconManager from '@app/components/FaviconManager';
import store from '@app/store';
import { ModalProvider } from '@shared/components/Modal';

import App from './app';

const appEl = document.querySelector('#app');
const root = createRoot(appEl);

root.render(
  <StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ModalProvider>
            <FaviconManager />

            <App />
          </ModalProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </StrictMode>,
);
