import { useTableContext } from '@shared/components/Table';

const possiblePages = ['50', '100', '200'];

export const PaginationRows = () => {
  const { size, setSize } = useTableContext();

  return (
    <>
      <div className='pagination-changer'>
        <div className='pagination-changer__item'>Visible rows:</div>
        {possiblePages.map(page => (
          <div
            key={page}
            className={`page-item${size === page ? ' is-active' : ''}`}
            onClick={() => setSize(page)}
            onKeyDown={() => setSize(page)}
            role='button'
            tabIndex={0}
          >
            {page}
          </div>
        ))}
      </div>
    </>
  );
};
