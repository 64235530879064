import { FC } from 'react';

import { useTableContext } from '@shared/components/Table';

interface IProps {
  id: string;
  isDisabled: boolean;
}

const TableCheckOneRow: FC<IProps> = ({ id, isDisabled }) => {
  const { toggleSelectedId, selectedIds } = useTableContext();

  return (
    <input
      type='checkbox'
      data-checkbox-id={id}
      disabled={isDisabled}
      checked={selectedIds.includes(id)}
      onChange={() => toggleSelectedId(id)}
    />
  );
};

export default TableCheckOneRow;
