import { usePageTitle } from '@app/hooks/usePageTitle';
import OrdersWidget from '@widgets/orders';

const OrdersWithdrawalsPage = () => {
  usePageTitle('Withdrawal orders');

  return <OrdersWidget type='withdrawal' />;
};

export default OrdersWithdrawalsPage;
