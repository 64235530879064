import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TWalletType } from '../model/types';

interface WalletState {
  type: TWalletType;
}

const initialState: WalletState = {
  type: 'deposit',
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletType(state, action: PayloadAction<TWalletType>) {
      state.type = action.payload;
    },
  },
});

export const actions = walletSlice.actions;
export default walletSlice.reducer;

export const useWalletType = () => useTypedSelector((state: RootState) => state.wallet.type);
