import { baseApi } from '@app/store/index.api';

import { IUserResponse } from '../model/types';

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<IUserResponse, void>({
      query: () => '/user',
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userApi;
