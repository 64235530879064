import { baseApi } from '@app/store/index.api';

import { IMerchantTokenResponse } from '../model/types';

const merchantMeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    generateMerchantToken: builder.mutation<IMerchantTokenResponse, void>({
      query: query => {
        return {
          url: '/merchants/token',
          method: 'POST',
          body: query,
        };
      },
    }),
  }),
});

export const { useGenerateMerchantTokenMutation } = merchantMeApi;
