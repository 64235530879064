import { z } from 'zod';

export const ZBankSchema = z.object({
  id: z.string(),
  name: z.string(),
  bank_validator_name: z.string(),
  holder_name: z.string().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
});

export type ZBank = z.infer<typeof ZBankSchema>;
export const ZBankResponseSchema = z.array(ZBankSchema);
