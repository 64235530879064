import { useFilter } from '@features/table-filter';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useTransactionWithdrawalQuery } from '../api';
import type { ITransactionWithdrawalFilter } from '../model/types';

export const useTransactionsWithdrawal = () => {
  const filter = useFilter<ITransactionWithdrawalFilter>();

  const {
    data: transactions,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useTransactionWithdrawalQuery(filter.currentFilter.toString());

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    transactions,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
