import { useEffect } from 'react';

import { toast } from 'react-toastify';

import useActions from '@app/store/hooks/useActions';
import { useHasServerError } from '@entities/page';

const useServerErrorHandler = () => {
  const hasServerError = useHasServerError();
  const { setServerError } = useActions();

  useEffect(() => {
    if (hasServerError) {
      toast.error('Server error. Please try again later.', {
        onClose: () => setServerError(false),
      });
    }
  }, [hasServerError, setServerError]);
};

export default useServerErrorHandler;
