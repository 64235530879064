import { usePageTitle } from '@app/hooks/usePageTitle';
import LogsWidget from '@widgets/logs';

const LogsPage = () => {
  usePageTitle('Logs');

  return <LogsWidget />;
};

export default LogsPage;
