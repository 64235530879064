import { FC } from 'react';

import './modal-confirm.css';

interface IProps {
  title: string;
  message: string;
  onConfirm: (() => void) | undefined;
  onCancel: () => void;
}

const ModalConfirm: FC<IProps> = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className='modal-confirm'>
      <div className='modal-confirm__title'>{title}</div>
      <div className='modal-confirm__message'>{message}</div>
      <div className='modal-confirm__btns'>
        <button className='btn-confirm' onClick={onConfirm}>
          Yes
        </button>
        <button className='btn-cancel secondary-btn' onClick={onCancel}>
          No
        </button>
      </div>
    </div>
  );
};

export default ModalConfirm;
