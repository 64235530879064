import { baseApi } from '@app/store/index.api';

import {
  IIPWhitelistResponse,
  INewIPWhitelistResponse,
  IDeleteIPWhitelistResponse,
  IDeleteIPWhitelistQuery,
  IEditIPWhitelistResponse,
  IEditIPWhitelistQuery,
} from '../model/types';

const IPWhitelistApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getIPs: builder.query<IIPWhitelistResponse, string>({
      query: query => {
        return {
          url: `merchant/ip?${query}`,
        };
      },
    }),
    createIP: builder.mutation<INewIPWhitelistResponse, FormData>({
      query: query => {
        return {
          url: 'merchant/ip',
          method: 'POST',
          body: query,
        };
      },
    }),
    deleteIP: builder.mutation<IDeleteIPWhitelistResponse, IDeleteIPWhitelistQuery>({
      query: query => {
        return {
          url: `merchant/ip/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
    toggleActiveStatus: builder.mutation<IEditIPWhitelistResponse, IEditIPWhitelistQuery>({
      query: query => {
        return {
          url: `merchant/ip/${query.id}`,
          method: 'PATCH',
        };
      },
    }),
  }),
});

export const {
  useGetIPsQuery,
  useCreateIPMutation,
  useDeleteIPMutation,
  useToggleActiveStatusMutation,
} = IPWhitelistApi;
