import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsBanksWidget from '@widgets/settings/banks';

const SettingsBanksPage = () => {
  usePageTitle('Banks');

  return <SettingsBanksWidget />;
};

export default SettingsBanksPage;
