import { FormEvent } from 'react';

import { MIDsSelect } from '@features/mids';
import { ProvidersSelect } from '@features/providers';
import { TrafficsSelect } from '@features/traffic';
import { WalletModesSelect, WalletStatusesSelect, WalletTypesSelect } from '@features/wallets';
import { FormItemWithError } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { IBaseObject, TUseMutationPatch } from '@shared/types/fetch-data';
import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';
import { StringOnlyKeys } from '@shared/types/utils';
import { isObject } from '@shared/utils/isObject';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T, TQuery, TResponse> {
  title: string;
  entity: T;
  excludeKeys: (keyof T)[];
  additionalKeys?: Partial<Record<keyof T, string>>[];
  renamedLabels?: Partial<Record<StringOnlyKeys<T>, string>>;
  secondErrorNames?: Partial<Record<keyof T, string>>[];
  useEditEntityMutation: TUseMutationPatch<TQuery, TResponse>;
  refetch: () => void;
  submitOnlyChangedFields?: boolean;
}

export const ModalEditEntity = <T extends IBaseObject, TQuery, TResponse>({
  title,
  entity,
  excludeKeys,
  additionalKeys = [],
  secondErrorNames = [],
  renamedLabels,
  useEditEntityMutation,
  refetch,
  submitOnlyChangedFields = false,
}: IProps<T, TQuery, TResponse>) => {
  const { closeModal } = useModal();

  const [editEntity, { isLoading, isError, error, isSuccess }] = useEditEntityMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formValues = Object.fromEntries(formData.entries()) as unknown as TQuery;

    if (submitOnlyChangedFields) {
      Object.entries(formValues).forEach(([key, value]) => {
        if (key !== 'id' && entity[key as keyof T] === value) {
          delete formValues[key as keyof TQuery];
        }
      });
    }

    try {
      const result = await editEntity(formValues);

      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasSecondErrorName = (name: keyof T) => {
    return secondErrorNames.some(item => item[name]);
  };

  const getSecondErrorName = (name: keyof T) => {
    return secondErrorNames.find(item => item[name])[name];
  };

  const renderItem = (key: keyof T, value: T[keyof T]) => {
    const keyString = String(key);
    const valueString = value && String(value);

    switch (key) {
      case 'description':
        return <textarea name={keyString} defaultValue={valueString} />;

      case 'email':
        return <input type='email' name={keyString} defaultValue={valueString} />;

      case 'logo':
        return <input type='file' name={keyString} />;

      case 'type':
        return <WalletTypesSelect selectedType={valueString} />;

      case 'status':
        return <WalletStatusesSelect selectedStatus={valueString} />;

      case 'parser_mode':
        return <WalletModesSelect selectedMode={valueString} />;

      case 'provider_id':
        return <ProvidersSelect selectedProvider={valueString} />;

      case 'mid_name':
        return <MIDsSelect selectedMID={valueString} />;

      case 'traffic':
        return <TrafficsSelect selectedTraffic={valueString} />;

      case 'login_credentials_json':
        return <textarea name={keyString} />;

      default:
        return <input type='text' name={keyString} defaultValue={valueString} />;
    }
  };

  const computedEntity = additionalKeys.reduce((acc, obj) => ({ ...acc, ...obj }), { ...entity });

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form' onSubmit={handleSubmit}>
        <input type='hidden' name='id' value={entity.id} />

        {getTypedObjectEntries(computedEntity)
          .filter(([key]) => !excludeKeys.includes(key))
          .map(([key, value]) => {
            if (isObject(value)) {
              return null;
            }

            const keyString = String(key);
            const label =
              renamedLabels?.[key as StringOnlyKeys<T>] ||
              `${transformObjectKeyToTitle(keyString)}:`;

            const secondErrorNameProps = hasSecondErrorName(key)
              ? { secondName: getSecondErrorName(key) }
              : {};

            return (
              <FormItemWithError
                key={keyString}
                name={keyString}
                {...secondErrorNameProps}
                label={label}
                isError={isError}
                error={error}
              >
                {renderItem(key, value)}
              </FormItemWithError>
            );
          })}
        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
