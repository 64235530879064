type TTransformCurrentFilter = Record<string, unknown>;

export const transformCurrentFilter = <T>(filter: T) => {
  const transformedFilter = {} as TTransformCurrentFilter;
  const searchParamsFilter = filter as URLSearchParams;

  const singleBracketPattern = /^filter\[(\w+)\]$/;
  const doubleBracketPattern = /^filter\[(\w+)\]\[(\w+)\]$/;

  searchParamsFilter.forEach((value, key) => {
    let newKey = key;

    if (singleBracketPattern.test(key)) {
      newKey = key.replace(singleBracketPattern, '$1');
      transformedFilter[newKey] = value;
    }

    if (doubleBracketPattern.test(key)) {
      const match = key.match(doubleBracketPattern);
      if (match) {
        const parentKey = match[1];
        const childKey = match[2];
        if (!transformedFilter[parentKey]) {
          transformedFilter[parentKey] = {};
        }
        (transformedFilter[parentKey] as TTransformCurrentFilter)[childKey] = value;
      }
    }
  });

  return transformedFilter;
};
