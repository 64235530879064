import { FC } from 'react';

import { Select } from '@shared/components/Form';

const walletTypes = [
  { id: 'deposit', label: 'Deposit' },
  { id: 'withdraw', label: 'Withdraw' },
  { id: 'settlement', label: 'Settlement' },
];

interface IProps {
  selectedType?: string;
  onChangeWalletType?: (type: string) => void;
}

export const WalletTypesSelect: FC<IProps> = ({ selectedType, onChangeWalletType }) => (
  <Select
    name='type'
    options={walletTypes}
    getOptionValue={type => type.id}
    getOptionLabel={type => type.label}
    selectedValue={selectedType}
    onChange={onChangeWalletType}
  />
);
