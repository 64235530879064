import { baseApi } from '@app/store/index.api';

import {
  IOrderDepositResponse,
  IOrderDepositUploadQuery,
  IOrderDepositUploadResponse,
} from '../model/types';

const orderDepositApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    orderDeposit: builder.query<IOrderDepositResponse, string>({
      query: query => {
        return {
          url: `/orders/deposits?${query}`,
        };
      },
    }),
    orderDepositDownload: builder.mutation<IOrderDepositUploadResponse, IOrderDepositUploadQuery>({
      query: query => {
        return {
          url: '/orders/deposits/upload',
          method: 'POST',
          body: query,
          responseHandler: async response => {
            return await response.json();
          },
        };
      },
    }),
  }),
});

export const { useOrderDepositQuery, useOrderDepositDownloadMutation } = orderDepositApi;
