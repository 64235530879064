import { useState, useEffect, useRef } from 'react';

import { IDashboardFilter, useDashboardCurrenciesQuery } from '@entities/dashboard';
import { ISelectMultiOptions } from '@shared/components/Form';
import useToday from '@shared/hooks/useToday';

import { useDashboardContext } from '../context';

export const useDashboardFilter = () => {
  const [selectedDate, setSelectedDate] = useState('');

  const [selectedCurrencyId, setSelectedCurrencyId] = useState('');
  const [selectedCurrencyKey, setSelectedCurrencyKey] = useState('');

  const [selectedMerchants, setSelectedMerchants] = useState<ISelectMultiOptions>([]);
  const [merchantOptions, setMerchantOptions] = useState<ISelectMultiOptions>([]);

  const [selectedMIDs, setSelectedMIDs] = useState<ISelectMultiOptions>([]);
  const [midOptions, setMIDOptions] = useState<ISelectMultiOptions>([]);

  const today = useToday();

  const isFirstRender = useRef(true);

  const { updateFilter, deleteFilterKey, setCanFetchDashboard, setCurrencySymbol } =
    useDashboardContext();

  const { data: dashboardCurrencies, isLoading, isError, error } = useDashboardCurrenciesQuery();

  // DATE
  // set default date to today
  useEffect(() => {
    setSelectedDate(today);
  }, [today]);

  // update filter date
  useEffect(() => {
    if (selectedDate) {
      updateDate(selectedDate);
    }
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // CURRENCY
  // set default currency to first in list
  useEffect(() => {
    if (dashboardCurrencies?.data && !Array.isArray(dashboardCurrencies.data)) {
      const firstCurrencyId = Object.values(dashboardCurrencies.data)[0].id;
      setSelectedCurrencyId(firstCurrencyId);
    }
  }, [dashboardCurrencies]); // eslint-disable-line react-hooks/exhaustive-deps

  // update filter currency
  useEffect(() => {
    if (selectedCurrencyId) {
      updateCurrency(selectedCurrencyId);
      setSelectedCurrencyKey(getCurrencyKey(selectedCurrencyId));
    }
  }, [selectedCurrencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  // MERCHANTS & MIDS
  // update merchants options on currency change
  useEffect(() => {
    if (dashboardCurrencies && selectedCurrencyKey) {
      if (isFirstRender.current) {
        setCurrencySymbol(selectedCurrencyKey);
        setCanFetchDashboard(true);
        isFirstRender.current = false;
      }

      const merchants = Object.values(dashboardCurrencies.data[selectedCurrencyKey].merchant);

      const merchantOptions = merchants.map(merchant => ({
        value: merchant.id,
        label: merchant.name,
      }));

      const mids = merchants.flatMap(merchant => merchant.mid);
      const midOptions = mids.map(mid => ({
        value: mid.id,
        label: mid.name,
      }));

      setMerchantOptions(merchantOptions);
      setMIDOptions(midOptions);
    }
  }, [selectedCurrencyKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // update filter merchants
  useEffect(() => {
    updateMerchants(selectedMerchants);

    // update MIDs options on merchants change
    if (dashboardCurrencies && selectedCurrencyKey) {
      let updatedMIDOptions = [];

      if (selectedMerchants.length > 0) {
        // filter MIDs by selected merchants
        if (selectedMIDs.length > 0) {
          const filteredMIDs = filterMIDsByMerchant();
          setSelectedMIDs(filteredMIDs);
        }

        // filter MIDs by selected merchants
        updatedMIDOptions = selectedMerchants.flatMap(({ value }) => {
          const merchantMIDs =
            dashboardCurrencies.data[selectedCurrencyKey].merchant[value]?.mid || [];

          return merchantMIDs.map(mid => ({
            value: mid.id,
            label: mid.name,
          }));
        });
      } else {
        // show all MIDs if no merchants selected
        const merchants = dashboardCurrencies.data[selectedCurrencyKey]?.merchant || {};
        updatedMIDOptions = Object.values(merchants).flatMap(merchant =>
          merchant.mid.map(mid => ({
            value: mid.id,
            label: mid.name,
          })),
        );
      }

      setMIDOptions(updatedMIDOptions);
    }
  }, [selectedMerchants]); // eslint-disable-line react-hooks/exhaustive-deps

  // update filter MIDs
  useEffect(() => {
    updateMIDs(selectedMIDs);
  }, [selectedMIDs]); // eslint-disable-line react-hooks/exhaustive-deps

  // HELPER FUNCTIONS
  const getCurrencyKey = (currencyId: string) => {
    if (dashboardCurrencies) {
      const currencyKey = Object.keys(dashboardCurrencies.data).find(
        key => dashboardCurrencies.data[key].id === currencyId,
      );

      return currencyKey;
    }

    return '';
  };

  const filterMIDsByMerchant = () => {
    return selectedMIDs.filter(mid =>
      selectedMerchants.some(merchant =>
        dashboardCurrencies.data[selectedCurrencyKey].merchant[merchant.value].mid.some(
          merchantMID => merchantMID.id === mid.value,
        ),
      ),
    );
  };

  // CURRENT FILTERS
  const updateDate = (date: string) => {
    updateFilter({ 'filter[created_at][to]': date });
  };

  const updateCurrency = (currencyId: string) => {
    updateFilter({ 'filter[currency_id]': currencyId });
  };

  const updateMerchants = (merchants: ISelectMultiOptions) => {
    if (!merchants.length) {
      deleteFilterKey('filter[merchant_id][]');

      return;
    }

    const newMerchants: IDashboardFilter = {};

    merchants.forEach((merchant, index) => {
      newMerchants[`filter[merchant_id][][${index}]` as keyof IDashboardFilter] = merchant.value;
    });

    updateFilter(newMerchants);
  };

  const updateMIDs = (mids: ISelectMultiOptions) => {
    if (!mids.length) {
      deleteFilterKey('filter[mid_id][]');

      return;
    }

    const newMIDs: IDashboardFilter = {};

    mids.forEach((mid, index) => {
      newMIDs[`filter[mid_id][][${index}]` as keyof IDashboardFilter] = mid.value;
    });

    updateFilter(newMIDs);
  };

  return {
    today,
    selectedDate,
    setSelectedDate,

    selectedCurrencyId,
    setSelectedCurrencyId,
    selectedCurrencyKey,

    merchantOptions,
    setSelectedMerchants,

    midOptions,
    selectedMIDs,
    setSelectedMIDs,

    dashboardCurrencies,
    isLoading,
    isError,
    error,
  };
};
