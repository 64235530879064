import {
  createContext,
  useState,
  useContext,
  ReactNode,
  PropsWithChildren,
  KeyboardEvent,
} from 'react';

import { createPortal } from 'react-dom';

import Modal from '../components/Modal';
import { IModalInstance, TModalSize } from '../model/types';

import '../modal.css';

// Context
interface IModalContextProps<T> {
  openModal: (content: ReactNode, size?: TModalSize, additionalContext?: T) => void;
  closeModal: () => void;
  additionalContext: T | null;
}

/* eslint-disable */
const ModalContext = createContext<IModalContextProps<any> | undefined>(undefined);
/* eslint-disable */

// Provider
interface IModalProviderProps<T> {
  additionalContext?: T;
}
const ModalProvider = <T,>({ children }: PropsWithChildren<IModalProviderProps<T>>) => {
  const [modals, setModals] = useState<IModalInstance[]>([]);
  const [additionalContext, setAdditionalContext] = useState<T | null>(null);

  const openModal = (content: ReactNode, size: TModalSize = 'md', additionalContext?: T) => {
    setModals(prevModals => [...prevModals, { content, size }]);
    setAdditionalContext(additionalContext);
  };

  const closeModal = () => {
    setModals(prev => prev.slice(0, -1));
    setAdditionalContext(null);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, additionalContext }}>
      {children}

      {modals.length > 0 &&
        createPortal(
          <div className='modal-wrapper'>
            <div
              className='modal-overlay'
              onClick={closeModal}
              onKeyDown={handleKeyDown}
              role='button'
              tabIndex={0}
            ></div>
            {modals.map((modal, index) => (
              <Modal key={index} modal={modal} />
            ))}
          </div>,
          document.getElementById('popup-root'),
        )}
    </ModalContext.Provider>
  );
};

export const useModal = <T,>() => {
  const context = useContext<IModalContextProps<T>>(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};

export { ModalProvider };
