const LOCAL_BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;

function getApiUrl(domain: string) {
  const isLocalhost = domain.includes('localhost');
  if (isLocalhost && LOCAL_BASE_URL) {
    return LOCAL_BASE_URL;
  }

  const subdomain = domain.split('.')[0];
  const mainDomain = domain.split('.').slice(1).join('.');

  let apiBaseUrl = '';

  if (subdomain === 'stage') {
    apiBaseUrl = `https://api-stage.${mainDomain}/api`;
  } else if (subdomain === 'prod') {
    apiBaseUrl = `https://api.${mainDomain}/api`;
  }

  return apiBaseUrl || domain;
}

export const BASE_URL = getApiUrl(window.location.hostname);
