import { IIFSC, IIFSCTableColumns, INewIFSCQuery, useCreateIfscMutation } from '@entities/ifsc';
import { ModalNewEntity } from '@features/modal-entity-new';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  // THead settings
  const columnTitles: IIFSCTableColumns = {
    bank_name: 'Bank Name',
    ifsk: 'IFSC',
    branch: 'Branch',
    centre: 'Centre',
    district: 'District',
    state: 'State',
    city: 'City',
    address: 'Address',
    contact: 'Contact',
    micr: 'MICR',
    created_at: 'Created At',
  };

  // TBody settings
  const renderRow = (key: keyof IIFSCTableColumns, code: IIFSC) => {
    switch (key) {
      default:
        return code[key];
    }
  };

  // New merchant settings
  const newCurrencyKeys: (keyof INewIFSCQuery)[] = ['file'];
  const NewCurrency = () => (
    <ModalNewEntity
      title='New IFSC'
      entityKeys={newCurrencyKeys}
      useCreateEntityMutation={useCreateIfscMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewCurrency,
  };
};
