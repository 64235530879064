import { useState, useEffect } from 'react';

import { useLazyCountFeeQuery, useLazyGetFeeByMIDQuery } from '../api';

interface IProps {
  mid_id: string;
}

export const useFee = ({ mid_id }: IProps) => {
  const [amount, setAmount] = useState('');
  const [exchange_rate, setExchangeRate] = useState('');
  const [fee, setFee] = useState('');
  const [usdtAmount, setUsdtAmount] = useState('');

  const [getFee] = useLazyGetFeeByMIDQuery();
  const [countFee] = useLazyCountFeeQuery();

  // Fetch fee
  useEffect(() => {
    if (!mid_id) {
      setFee('');
      return;
    }

    const fetchFee = async () => {
      const result = await getFee({ mid_id });
      if (result?.data) {
        setFee(result.data.data.fee);
      }
    };

    fetchFee();
  }, [mid_id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch usdt amount
  useEffect(() => {
    if (!mid_id || !amount || !exchange_rate) {
      setUsdtAmount('');
      return;
    }

    const fetchUsdtAmount = async () => {
      const result = await countFee({ mid_id, amount, exchange_rate });
      if (result?.data) {
        setUsdtAmount(result.data.data.usdt_amount);
      }
    };

    fetchUsdtAmount();
  }, [mid_id, amount, exchange_rate]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    setAmount,
    setExchangeRate,
    fee,
    usdt_amount: usdtAmount,
  };
};
