import { baseApi } from '@app/store/index.api';

import {
  IOrderWithdrawalResponse,
  IOrderWithdrawalUploadQuery,
  IOrderWithdrawalUploadResponse,
} from '../model/types';

const orderWithdrawalApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    orderWithdrawal: builder.query<IOrderWithdrawalResponse, string>({
      query: query => {
        return {
          url: `/orders/withdrawals?${query}`,
        };
      },
    }),
    orderWithdrawalDownload: builder.mutation<
      IOrderWithdrawalUploadResponse,
      IOrderWithdrawalUploadQuery
    >({
      query: query => {
        return {
          url: '/orders/withdrawals/upload',
          method: 'POST',
          body: query,
          responseHandler: async response => {
            return await response.json();
          },
        };
      },
    }),
  }),
});

export const { useOrderWithdrawalQuery, useOrderWithdrawalDownloadMutation } = orderWithdrawalApi;
