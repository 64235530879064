import { z } from 'zod';

export const ZMethodSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  logo: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
});

export type ZMethod = z.infer<typeof ZMethodSchema>;
export const ZMethodResponseSchema = z.array(ZMethodSchema);
