import { FC } from 'react';

import { TFetchQueryError } from '@shared/types/fetch-data';
import { IZodValidationError, ZodErrorMessage } from '@shared/zod';

import './error.css';

interface IProps {
  error: TFetchQueryError | IZodValidationError;
}

const ErrorMessage: FC<IProps> = ({ error }) => {
  if (error && 'type' in error && error.type === 'zod') {
    return <ZodErrorMessage error={error} />;
  }

  return (
    <div className='error-message'>
      {error ? JSON.stringify(error) : 'An unknown error occurred'}
    </div>
  );
};

export default ErrorMessage;
