import './loading-spinner.css';

const LoadingSpinner = () => (
  <div className='loading-spinner'>
    <div className='loading'>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingSpinner;
