import { FC, ReactNode } from 'react';

import Card from '@shared/components/Card';
import { PieChart } from '@shared/components/Charts';
import { formatAmount } from '@shared/utils/formatAmount';
import { getCurrencySymbol } from '@shared/utils/getCurrencySymbol';

import { useDashboardContext } from '../context';

interface IProps {
  title: string;
  amount: string | null;
  tooltip: string;
  countContent: () => ReactNode;
  chartPercent?: number;
  chartFillColor?: string;
}

const DashboardItem: FC<IProps> = ({
  title,
  amount,
  tooltip,
  countContent,
  chartPercent,
  chartFillColor,
}) => {
  const { currencySymbol } = useDashboardContext();
  const symbol = getCurrencySymbol(currencySymbol);

  return (
    <div className='dashboard-item' title={tooltip}>
      <Card>
        <div className='dashboard-item__columns'>
          <div className='dashboard-item__details'>
            <div className='dashboard-item__title'>{title}</div>
            <div className='dashboard-item__amount'>
              {symbol}
              {formatAmount(amount)}
            </div>
            <div className='dashboard-item__count'>{countContent()}</div>
          </div>
          {chartPercent !== undefined && chartFillColor && (
            <div className='dashboard-item__chart'>
              <PieChart percent={chartPercent} fillColor={chartFillColor} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DashboardItem;
