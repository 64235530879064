import { usePageTitle } from '@app/hooks/usePageTitle';
import SettingsIPWhitelistWidget from '@widgets/settings/ip-whitelist';

const SettingsIPWhitelistPage = () => {
  usePageTitle('IP Whitelist');

  return <SettingsIPWhitelistWidget />;
};

export default SettingsIPWhitelistPage;
