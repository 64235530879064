import { useLocation } from 'react-router-dom';

import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { useTableContext } from '@shared/components/Table';

import { useBulkActions } from '../hooks/useBulkActions';

export const BulkOrders = <T,>() => {
  const { currentFilter } = useTableContext<T>();
  const { handleAction, isDownloadLoading, isResendCallbackLoading } =
    useBulkActions(currentFilter);

  const location = useLocation();
  const pathname = location.pathname.replaceAll('/', '-');

  return (
    <div className='bulk-btns'>
      <button
        className='accent-btn'
        onClick={() => handleAction(`download${pathname}`)}
        disabled={isDownloadLoading}
      >
        Download
        <DownloadIcon />
      </button>
      <button
        className='success-btn'
        onClick={() => handleAction('resend-callback')}
        disabled={isResendCallbackLoading}
      >
        Resend Callback
        <SendIcon />
      </button>
    </div>
  );
};
