import { FC } from 'react';

import { trafficTypes } from '@entities/traffic';
import { getSelectOptions, Select } from '@shared/components/Form';

const traffics = getSelectOptions(trafficTypes);

interface IProps {
  selectedTraffic?: string;
  onChangeTraffic?: (traffic: string) => void;
}

export const TrafficsSelect: FC<IProps> = ({ selectedTraffic = '' }) => {
  if (traffics) {
    const selectedValueProp = selectedTraffic ? { selectedValue: selectedTraffic } : {};

    return (
      <Select
        name='traffic'
        options={traffics}
        getOptionValue={mid => mid.id}
        getOptionLabel={mid => mid.label}
        {...selectedValueProp}
      >
        <option value=''>Select traffic</option>
      </Select>
    );
  }

  return null;
};
