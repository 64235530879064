import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageFeaturesState {
  hasServerError: boolean;
  title: string;
  isNeedCurrentPageDataRefetch: boolean;
}

const initialState: PageFeaturesState = {
  hasServerError: false,
  title: '',
  isNeedCurrentPageDataRefetch: false,
};

const pageFeaturesSlice = createSlice({
  name: 'pageFeatures',
  initialState,
  reducers: {
    setServerError: (state, action: PayloadAction<boolean>) => {
      state.hasServerError = action.payload;
    },
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setIsNeedCurrentPageDataRefetch: (state, action: PayloadAction<boolean>) => {
      state.isNeedCurrentPageDataRefetch = action.payload;
    },
  },
});

export const actions = pageFeaturesSlice.actions;
export default pageFeaturesSlice.reducer;

export const useTitle = () => {
  return useTypedSelector((state: RootState) => state.pageFeatures.title);
};
export const useIsNeedCurrentPageDataRefetch = () => {
  return useTypedSelector((state: RootState) => state.pageFeatures.isNeedCurrentPageDataRefetch);
};

export const useHasServerError = () => {
  return useTypedSelector((state: RootState) => state.pageFeatures.hasServerError);
};
