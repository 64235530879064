import { useState, useEffect } from 'react';

import useActions from '@app/store/hooks/useActions';
import { useLazyGetUserQuery } from '@entities/user';

import { useToken } from '../slice';

export const useAuth = () => {
  const { setCurrentUser } = useActions();
  const token = useToken();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!token);

  const [getUser, { isLoading }] = useLazyGetUserQuery();

  useEffect(() => {
    if (token) {
      const fetchUser = async () => {
        const result = await getUser();

        if ('data' in result) {
          setCurrentUser(result.data);
        }
      };

      fetchUser();
    }

    setIsAuthenticated(!!token);
  }, [token]); // eslint-disable-line

  return { isAuthenticated, isUserLoading: isLoading };
};
