import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetMIDsQuery } from '../api';
import type { IMIDFilter } from '../model/types';

interface IProps {
  merchantIds?: string[];
  currencyId?: string;
  methodId?: string;

  disableUrlFilter?: boolean;
}

export const useMIDs = ({ merchantIds, currencyId, methodId, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<IMIDFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter, deleteFilterKey } = filter;

  const {
    data: mids,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetMIDsQuery(currentFilter.toString());

  useEffect(() => {
    if (!merchantIds?.length) {
      deleteFilterKey('filter[merchant][]');

      return;
    }

    const newMerchants: IMIDFilter = {};

    merchantIds.forEach((id, index, arr) => {
      const key = arr.length > 1 ? `filter[merchant][][${index}]` : 'filter[merchant][]';

      newMerchants[key as keyof IMIDFilter] = id;
    });

    updateFilter(newMerchants);
  }, [merchantIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[currency]': currencyId });
  }, [currencyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[method]': methodId });
  }, [methodId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    mids,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
