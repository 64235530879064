import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useProviders } from '@entities/provider';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  name?: string;

  selectedProvider?: string;
  onChangeProvider?: (providerId: string) => void;
}

export const ProvidersSelect: FC<IProps> = ({ name = '', selectedProvider, onChangeProvider }) => {
  const { providers, isLoading, error, isFetching } = useProviders({
    name,
    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (providers) {
    const selectedValueProp = selectedProvider ? { selectedValue: selectedProvider } : {};

    return (
      <Select
        name='provider_id'
        options={providers.data}
        getOptionValue={provider => provider.id}
        getOptionLabel={provider => provider.short_name}
        onChange={onChangeProvider}
        isDisabled={isFetching}
        {...selectedValueProp}
      >
        <option value=''>Select provider</option>
      </Select>
    );
  }

  return null;
};
