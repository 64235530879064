import { FC, useState, useEffect, ChangeEvent } from 'react';

import { useUpdateWalletModeMutation } from '@entities/wallets';
import { IWalletsMonitoring } from '@entities/wallets/monitoring';
import { Switch } from '@mui/material';
import { FormItemWithError } from '@shared/components/Form';

interface IProps {
  monitoring: IWalletsMonitoring;
  refetch: () => void;
}

export const ParserModeToggler: FC<IProps> = ({ monitoring, refetch }) => {
  const [updateWalletMode, { error, isError, isLoading, isSuccess }] =
    useUpdateWalletModeMutation();

  const [localValue, setLocalValue] = useState<string | null>(monitoring.parser_mode);
  const [pendingValue, setPendingValue] = useState<string | null>(null);

  useEffect(() => {
    if (pendingValue === null) {
      setLocalValue(monitoring.parser_mode);
    }
  }, [monitoring.parser_mode, pendingValue]);

  useEffect(() => {
    if (isSuccess && pendingValue !== null) {
      setLocalValue(pendingValue);
      setPendingValue(null);
      refetch();
    }
  }, [isSuccess, pendingValue, refetch]);

  useEffect(() => {
    if (isError && pendingValue !== null) {
      setPendingValue(null);
    }
  }, [isError, pendingValue]);

  const handleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newValue = checked ? 'auto' : 'off';

    setPendingValue(newValue);
    updateWalletMode({ id: monitoring.id, mode: newValue }).unwrap();
  };

  return (
    <FormItemWithError name={'mode'} error={error} isError={isError}>
      <Switch
        checked={localValue === 'auto'}
        onChange={handleChange}
        disabled={isLoading || pendingValue !== null}
      />
    </FormItemWithError>
  );
};
