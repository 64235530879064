const currencyIcon = {
  USD: '$',
  EUR: '€',
  INR: '₹',
  BDT: '৳',
};

type CurrencyCode = keyof typeof currencyIcon;

function isCurrencyCode(code: string): code is CurrencyCode {
  return code in currencyIcon;
}

export const getCurrencySymbol = (code: string) => {
  if (isCurrencyCode(code)) {
    return currencyIcon[code];
  }

  return code;
};
