import { baseApi } from '@app/store/index.api';

import {
  ICurrencyResponse,
  INewCurrencyResponse,
  IEditCurrencyQuery,
  IEditCurrencyResponse,
  IDeleteCurrencyResponse,
  IDeleteCurrencyQuery,
} from '../model/types';

const currencyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCurrencies: builder.query<ICurrencyResponse, string>({
      query: query => {
        return {
          url: `currencies?${query}`,
        };
      },
    }),
    createCurrency: builder.mutation<INewCurrencyResponse, FormData>({
      query: query => {
        return {
          url: 'currencies',
          method: 'POST',
          body: query,
        };
      },
    }),
    editCurrency: builder.mutation<IEditCurrencyResponse, IEditCurrencyQuery>({
      query: query => {
        return {
          url: `currencies/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
    deleteCurrency: builder.mutation<IDeleteCurrencyResponse, IDeleteCurrencyQuery>({
      query: query => {
        return {
          url: `currencies/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useCreateCurrencyMutation,
  useEditCurrencyMutation,
  useDeleteCurrencyMutation,
} = currencyApi;
