import { z } from 'zod';

export const ZMerchantSchema = z.object({
  id: z.string(),
  domain: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  short_name: z.string(),
});

export type ZMerchant = z.infer<typeof ZMerchantSchema>;
export const ZMerchantResponseSchema = z.array(ZMerchantSchema);
