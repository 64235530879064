import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import useActions from '@app/store/hooks/useActions';
import { useTimeZone } from '@entities/datetime';
import { useIsNeedCurrentPageDataRefetch } from '@entities/page';

const needRefetchPages = [
  '/requests/withdrawals',
  '/requests/deposits',
  '/orders/withdrawals',
  '/orders/deposits',
  '/settlements',
];

let visitedPages: string[] = [];

interface IProps {
  refetch: () => void;
}

export const useRefetchCurrentPageData = ({ refetch }: IProps) => {
  const isNeedCurrentPageDataRefetch = useIsNeedCurrentPageDataRefetch();
  const { setIsNeedCurrentPageDataRefetch } = useActions();
  const location = useLocation();
  const timeZone = useTimeZone();
  const isFirstRender = useRef(true);

  useEffect(() => {
    const isNeedStopRefetch = needRefetchPages.every(page => visitedPages.includes(page));
    if (isNeedStopRefetch) {
      setIsNeedCurrentPageDataRefetch(false);
      visitedPages = [];
      return;
    }

    if (isNeedCurrentPageDataRefetch && !visitedPages.includes(location.pathname)) {
      visitedPages = Array.from(new Set([...visitedPages, location.pathname]));

      refetch();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFirstRender.current && isNeedCurrentPageDataRefetch) {
      visitedPages = [location.pathname];

      refetch();
    }

    isFirstRender.current = false;
  }, [timeZone]); // eslint-disable-line react-hooks/exhaustive-deps
};
