import { FC } from 'react';

import { TModalSize, useModal } from '@shared/components/Modal';

interface IProps {
  text: string;
  modalContent: JSX.Element;
  size?: TModalSize;
}

const ModalOpenBtn: FC<IProps> = ({ text, modalContent, size }) => {
  const { openModal } = useModal();

  return <button onClick={() => openModal(modalContent, size)}>{text}</button>;
};

export default ModalOpenBtn;
