import { baseApi } from '@app/store/index.api';

import {
  IProviderResponse,
  INewProviderResponse,
  IEditProviderQuery,
  IEditProviderResponse,
  IDeleteProviderResponse,
  IDeleteProviderQuery,
} from '../model/types';

const providerApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProviders: builder.query<IProviderResponse, string>({
      query: query => {
        return {
          url: `providers?${query}`,
        };
      },
    }),
    createProvider: builder.mutation<INewProviderResponse, FormData>({
      query: query => {
        return {
          url: 'providers',
          method: 'POST',
          body: query,
        };
      },
    }),
    editProvider: builder.mutation<IEditProviderResponse, IEditProviderQuery>({
      query: query => {
        return {
          url: `providers/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
    deleteProvider: builder.mutation<IDeleteProviderResponse, IDeleteProviderQuery>({
      query: query => {
        return {
          url: `providers/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useCreateProviderMutation,
  useEditProviderMutation,
  useDeleteProviderMutation,
} = providerApi;
