import { ChangeEvent, FormEvent } from 'react';

import ErrorMessage from '@shared/components/ErrorMessage';
import {
  FormItemWithError,
  ISelectMultiOptions,
  Select,
  SelectMulti,
} from '@shared/components/Form';
import LoadingSpinner from '@shared/components/LoadingSpinner';

import { useDashboardContext } from '../context';
import { useDashboardFilter } from '../hooks/useDashboardFilter';

const DashboardFilter = () => {
  const { filterError, setCanFetchDashboard, setCurrencySymbol } = useDashboardContext();

  const {
    today,
    selectedDate,
    setSelectedDate,

    selectedCurrencyId,
    setSelectedCurrencyId,
    selectedCurrencyKey,

    merchantOptions,
    setSelectedMerchants,

    midOptions,
    selectedMIDs,
    setSelectedMIDs,

    dashboardCurrencies,
    isLoading,
    isError,
    error,
  } = useDashboardFilter();

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleMerchantChange = (selectedMerchantsOptions: ISelectMultiOptions) => {
    setSelectedMerchants(selectedMerchantsOptions);
  };

  const handleMIDChange = (selectedMIDsOptions: ISelectMultiOptions) => {
    setSelectedMIDs(selectedMIDsOptions);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setCurrencySymbol(selectedCurrencyKey);

    // fetch dashboard data
    setCanFetchDashboard(true);
  };

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (dashboardCurrencies) {
    if (Array.isArray(dashboardCurrencies.data) && !dashboardCurrencies.data.length)
      return <p>No currencies found.</p>;

    const currencies = Object.keys(dashboardCurrencies.data).map(key => {
      const { id, code } = dashboardCurrencies.data[key];

      return [id, code];
    });

    const isError = !!filterError;

    return (
      <form action='/dashboard/currencies' className='dashboard-filter' onSubmit={handleSubmit}>
        <div className='dashboard-filter__item'>
          <FormItemWithError
            label='Date:'
            name='filter.created_at.to'
            error={filterError}
            isError={isError}
          >
            <input type='date' value={selectedDate} onChange={handleDateChange} max={today} />
          </FormItemWithError>
        </div>

        <div className='dashboard-filter__item'>
          <FormItemWithError
            label='Currency:'
            name='filter.currency_id'
            error={filterError}
            isError={isError}
          >
            <Select
              name='filter.currency_id'
              options={currencies}
              getOptionLabel={([, code]) => code}
              getOptionValue={([id]) => id}
              onChange={setSelectedCurrencyId}
              selectedValue={selectedCurrencyId}
            />
          </FormItemWithError>
        </div>

        <div className='dashboard-filter__item'>
          <FormItemWithError
            label='Merchant:'
            name='filter.merchant_id'
            error={filterError}
            isError={isError}
          >
            <SelectMulti
              name='merchant_id'
              options={merchantOptions}
              onChange={handleMerchantChange}
            />
          </FormItemWithError>
        </div>

        <div className='dashboard-filter__item'>
          <FormItemWithError
            label='MID:'
            name='filter.mid_id.0'
            secondName='filter.mid_id.1'
            error={filterError}
            isError={isError}
          >
            <SelectMulti
              name='mid_id'
              options={midOptions}
              onChange={handleMIDChange}
              value={selectedMIDs}
            />
          </FormItemWithError>
        </div>

        <button type='submit'>Apply</button>
      </form>
    );
  }

  return null;
};

export default DashboardFilter;
