import { FC } from 'react';

import { walletParserModes } from '@entities/wallets';
import { Select } from '@shared/components/Form';

interface IProps {
  selectedMode?: string;
  onChangeWalletMode?: (mode: string) => void;
}

export const WalletModesSelect: FC<IProps> = ({ selectedMode, onChangeWalletMode }) => (
  <Select
    name='mode'
    options={walletParserModes}
    getOptionValue={mode => mode.id}
    getOptionLabel={mode => mode.label}
    selectedValue={selectedMode}
    onChange={onChangeWalletMode}
  />
);
