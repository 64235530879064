import { FC, PropsWithChildren } from 'react';

import '../table.css';

interface IProps {
  className?: string;
}

export const TableContainer: FC<PropsWithChildren<IProps>> = ({ children, className }) => {
  return <div className={`table-container ${className}`}>{children}</div>;
};
