import { baseApi } from '@app/store/index.api';

import { IConfigResponse } from '../model/types';

const configApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getConfig: builder.query<IConfigResponse, void>({
      query: () => '/config',
    }),
  }),
});

export const { useGetConfigQuery } = configApi;
