import { FC, PropsWithChildren } from 'react';

export const TableActions: FC<PropsWithChildren> = ({ children }) => {
  return <div className='table-actions'>{children}</div>;
};

export const TableActionsItem: FC<PropsWithChildren> = ({ children }) => {
  return <div className='table-actions-item'>{children}</div>;
};

export const TableActionsItemCell: FC<PropsWithChildren> = ({ children }) => {
  return <div className='table-actions-item__cell'>{children}</div>;
};
