import { useEffect, useState } from 'react';

type ApiError = {
  message: string;
  errors?: Record<string, string | string[]>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */

const isApiError = (error: any): error is { data: ApiError } => {
  return error?.data?.message !== undefined || error?.data?.errors !== undefined;
};

export const useTypedFormError = (isError: boolean, error: any) => {
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isError && isApiError(error)) {
      const simplifiedErrors: Record<string, string> = {};

      if (error.data.errors) {
        for (const key in error.data.errors) {
          simplifiedErrors[key] = Array.isArray(error.data.errors[key])
            ? (error.data.errors[key][0] as string)
            : (error.data.errors[key] as string);
        }
      } else {
        simplifiedErrors['message'] = error.data.message;
      }

      setErrorMessages(simplifiedErrors);
    } else {
      setErrorMessages({});
    }
  }, [isError, error]);

  const renderErrorMessage = (key: string): string => {
    if (key === 'general') return errorMessages['message'] || '';

    return errorMessages[key] || '';
  };

  const hasErrorForKey = (key: string): boolean => {
    if (key === 'general') return !!errorMessages['message'];

    return !!errorMessages[key];
  };

  return { renderErrorMessage, hasErrorForKey };
};
