export const getSelectOptions = <T>(entities: T[]) => {
  if (entities) {
    return entities.map(entity => ({
      id: entity,
      label: entity,
    }));
  }

  return null;
};
