import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTableContext } from '@shared/components/Table';

const THeadToggler = () => {
  const { isTheadVisible, setIsTheadVisible } = useTableContext();

  return (
    <div
      className='btn thead-toggler'
      onClick={() => setIsTheadVisible(!isTheadVisible)}
      onKeyDown={() => setIsTheadVisible(!isTheadVisible)}
      role='button'
      tabIndex={0}
    >
      Toggle Filter form {isTheadVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </div>
  );
};

export default THeadToggler;
