import { FC } from 'react';

import { useBulkApproveRequestMutation, useBulkRejectRequestMutation } from '@entities/requests';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useModal } from '@shared/components/Modal';
import { useTableContext } from '@shared/components/Table';

import { ConfirmApproveRejectRequest } from './ConfirmApproveRejectRequest';

interface IProps {
  refetch: () => void;
}

export const BulkRequests: FC<IProps> = ({ refetch }) => {
  const { openModal } = useModal();

  const { selectedIds, setSelectedIds } = useTableContext();

  return (
    <div className='bulk-btns'>
      <button
        onClick={() =>
          openModal(
            <ConfirmApproveRejectRequest
              data={{ ids: selectedIds, reason: '' }}
              message='Do you want to reject ALL selected requests?'
              mutation={useBulkRejectRequestMutation}
              mutationErrorName='ids'
              refetch={refetch}
            />,
            'md',
            { selectedIds, setSelectedIds },
          )
        }
      >
        Reject All
        <CancelIcon />
      </button>
      <button
        className='success-btn'
        onClick={() =>
          openModal(
            <ConfirmApproveRejectRequest
              data={{ ids: selectedIds, reason: '' }}
              message='Do you want to approve ALL selected requests?'
              mutation={useBulkApproveRequestMutation}
              mutationErrorName='ids'
              refetch={refetch}
            />,
            'md',
            { selectedIds, setSelectedIds },
          )
        }
      >
        Approve All
        <CheckCircleIcon />
      </button>
    </div>
  );
};
