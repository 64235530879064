import { useModal } from '@shared/components/Modal';

import { ViewApiKeys } from './components/ViewApiKeys';

const SettingsApiWidget = () => {
  const { openModal } = useModal();

  return (
    <button className='accent-btn' onClick={() => openModal(<ViewApiKeys />, 'sm')}>
      API credentials
    </button>
  );
};

export default SettingsApiWidget;
