import { BASE_URL } from '@app/settings/base-url';
import { baseApi } from '@app/store/index.api';

import { ILoginQuery, ILoginResponse } from '../model/types';

const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    loginAdmin: builder.mutation<ILoginResponse, ILoginQuery>({
      query: credentials => ({
        url: `${BASE_URL}/auth/backoffice`,
        method: 'POST',
        body: {
          ...credentials,
        },
      }),
    }),
    loginMerchant: builder.mutation<ILoginResponse, ILoginQuery>({
      query: credentials => ({
        url: `${BASE_URL}/auth/merchant`,
        method: 'POST',
        body: {
          ...credentials,
          as: 'merchant',
        },
      }),
    }),
    loginProvider: builder.mutation<ILoginResponse, ILoginQuery>({
      query: credentials => ({
        url: `${BASE_URL}/auth/backoffice`,
        method: 'POST',
        body: {
          ...credentials,
          as: 'operator',
        },
      }),
    }),
  }),
});

export const { useLoginAdminMutation, useLoginMerchantMutation, useLoginProviderMutation } =
  authApi;
