import { FC, PropsWithChildren } from 'react';

export const Table: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='table-block'>
      <table width='100%' cellPadding='0' cellSpacing='0'>
        {children}
      </table>
    </div>
  );
};
