import { useConfig } from '@entities/config';
import ErrorMessage from '@shared/components/ErrorMessage';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Table, TableContainer, TBodyHorizontal } from '@shared/components/Table';

const SettingsConfigWidget = () => {
  const { config, isError, error, isLoading } = useConfig();

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (config) {
    return (
      <TableContainer className='table-horizontal'>
        <Table>
          <TBodyHorizontal rows={config.data} />
        </Table>
      </TableContainer>
    );
  }

  return null;
};

export default SettingsConfigWidget;
