import { ChangeEvent, PropsWithChildren } from 'react';

interface IProps<T> {
  name: string;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;

  selectedValue?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
}

export const Select = <T,>({
  name,
  options,
  getOptionLabel,
  getOptionValue,

  selectedValue,
  onChange,
  isDisabled = false,
  children,
}: PropsWithChildren<IProps<T>>) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const valueProps = selectedValue ? { defaultValue: selectedValue } : {};

  return (
    <select name={name} onChange={handleChange} disabled={isDisabled} {...valueProps}>
      {children}
      {options.map(option => (
        <option key={getOptionValue(option)} value={getOptionValue(option)}>
          {getOptionLabel(option)}
        </option>
      ))}
    </select>
  );
};
