const formatSecondsToTime = (totalSeconds: number | string): string => {
  const secondsNum = typeof totalSeconds === 'string' ? parseInt(totalSeconds, 10) : totalSeconds;

  if (isNaN(secondsNum) || secondsNum < 0) {
    throw new Error('Total seconds must be a non-negative number');
  }

  const seconds = secondsNum % 60;
  const totalMinutes = Math.floor(secondsNum / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  const pad = (num: number): string => String(num).padStart(2, '0');

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  return `${pad(minutes)}:${pad(seconds)}`;
};

export default formatSecondsToTime;
