import { FC, createContext, useContext, useState, PropsWithChildren } from 'react';

import { IDashboardFilter } from '@entities/dashboard';
import { useFilter } from '@features/table-filter';
import { TFetchQueryError } from '@shared/types/fetch-data';

interface IContextValues {
  currentFilter: URLSearchParams;
  updateFilter: (filters: IDashboardFilter) => void;
  deleteFilterKey: (key: string) => void;
  canFetchDashboard: boolean;
  setCanFetchDashboard: (canFetchDashboard: boolean) => void;
  filterError: TFetchQueryError;
  setFilterError: (error: TFetchQueryError) => void;
  currencySymbol: string;
  setCurrencySymbol: (currencySymbol: string) => void;
}

const DashboardContext = createContext<IContextValues | undefined>(undefined);

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const { currentFilter, updateFilter, deleteFilterKey } = useFilter<IDashboardFilter>({
    hasPagination: false,
  });
  const [canFetchDashboard, setCanFetchDashboard] = useState(false);
  const [filterError, setFilterError] = useState<TFetchQueryError>(undefined);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const contextValues: IContextValues = {
    currentFilter,
    updateFilter,
    deleteFilterKey,
    canFetchDashboard,
    setCanFetchDashboard,
    filterError,
    setFilterError,
    currencySymbol,
    setCurrencySymbol,
  };

  return <DashboardContext.Provider value={contextValues}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = (): IContextValues => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }

  return context;
};
