import { usePageTitle } from '@app/hooks/usePageTitle';
import RequestsWidget from '@widgets/requests';

const RequestsWithdrawalsPage = () => {
  usePageTitle('Withdrawal requests');

  return <RequestsWidget type='withdrawal' />;
};

export default RequestsWithdrawalsPage;
