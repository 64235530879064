const Logo = () => {
  const getLogoName = () => {
    const domain = window.location.hostname;

    switch (domain) {
      case 'prod.lakhpay.com':
        return {
          siteName: 'LakhPay',
          className: 'lakhpay-logo',
        };

      default:
        return {
          siteName: 'Paynoomo',
          className: 'paynoomo-logo',
        };
    }
  };

  const { siteName, className } = getLogoName();

  return <div className={`sidebar-logo ${className}`}>{siteName}</div>;
};

export default Logo;
