import { baseApi } from '@app/store/index.api';

import { IMetabaseChartResponse, IMetabaseChartQuery } from '../model/types';

const metabaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMetabaseChart: builder.query<IMetabaseChartResponse, IMetabaseChartQuery>({
      query: ({ question_id }) => {
        return {
          url: '/dashboard/metabase',
          method: 'GET',
          params: {
            question_id,
          },
        };
      },
    }),
  }),
});

export const { useGetMetabaseChartQuery } = metabaseApi;
