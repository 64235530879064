import { usePageTitle } from '@app/hooks/usePageTitle';
import TransactionsWidget from '@widgets/transactions';

const TransactionsWithdrawalsPage = () => {
  usePageTitle('Withdrawal Transactions');

  return <TransactionsWidget type='withdrawal' />;
};

export default TransactionsWithdrawalsPage;
