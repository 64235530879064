import { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import { useBulkResendCallbackOrderMutation } from '@entities/orders';
import { useOrderDepositDownloadMutation } from '@entities/orders/deposit';
import { useOrderWithdrawalDownloadMutation } from '@entities/orders/withdrawal';
import { transformCurrentFilter } from '@features/table-filter';
import { useTableContext } from '@shared/components/Table';
import { isApiError } from '@shared/types/fetch-data';

export const useBulkActions = <T>(currentFilter: T) => {
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const { selectedIds, setSelectedIds } = useTableContext();

  const [
    bulkResendCallback,
    {
      isLoading: isResendCallbackLoading,
      isError: isResendCallbackError,
      error: resendCallbackError,
      isSuccess: isResendCallbackSuccess,
    },
  ] = useBulkResendCallbackOrderMutation();

  const [
    uploadOrderDeposit,
    {
      isLoading: isUploadOrderDepositLoading,
      isError: isUploadOrderDepositError,
      error: uploadOrderDepositError,
    },
  ] = useOrderDepositDownloadMutation();

  const [
    uploadOrderWithdrawal,
    {
      isLoading: isUploadOrderWithdrawalLoading,
      isError: isUploadOrderWithdrawalError,
      error: uploadOrderWithdrawalError,
    },
  ] = useOrderWithdrawalDownloadMutation();

  const isDownloadLoading = isUploadOrderDepositLoading || isUploadOrderWithdrawalLoading;

  useEffect(() => {
    if (isResendCallbackLoading || isDownloadLoading) {
      setIsError(false);
      setError(null);
      setIsSuccess(false);
    }
  }, [isDownloadLoading, isResendCallbackLoading]);

  useEffect(() => {
    if (isResendCallbackError) {
      setIsError(true);
      setError(resendCallbackError);
    }
    if (isUploadOrderDepositError) {
      setIsError(true);
      setError(uploadOrderDepositError);
    }
    if (isUploadOrderWithdrawalError) {
      setIsError(true);
      setError(uploadOrderWithdrawalError);
    }
  }, [
    isResendCallbackError,
    resendCallbackError,
    isUploadOrderDepositError,
    uploadOrderDepositError,
    isUploadOrderWithdrawalError,
    uploadOrderWithdrawalError,
  ]);

  useEffect(() => {
    if (isResendCallbackSuccess) {
      setIsSuccess(true);
    }
  }, [isResendCallbackSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setSelectedIds([]);

      toast.success('Resend callbacks order successfully', {
        onOpen: () => {
          setIsSuccess(false);
        },
      });
    }

    if (isError && error && isApiError(error)) {
      toast.error(error.data.message, {
        onOpen: () => {
          setIsError(false);
        },
      });
    }
  }, [isSuccess, isError, error, setIsError, setIsSuccess, setSelectedIds]);

  const handleAction = async (action: string) => {
    toast.dismiss();

    const mappedFilter = transformCurrentFilter<T>(currentFilter);
    const hasSelectedIds = selectedIds.length > 0;
    const hasFilter = Object.keys(mappedFilter).length > 0;

    const uploadFilter = hasSelectedIds
      ? { filter: { ids: selectedIds } }
      : hasFilter
        ? { filter: mappedFilter }
        : {};

    switch (action) {
      case 'resend-callback':
        bulkResendCallback({ ids: selectedIds });

        break;

      case 'download-orders-deposit':
        const responseDeposit = await uploadOrderDeposit(uploadFilter);

        if ('data' in responseDeposit) {
          const jsonResponse = responseDeposit.data;

          window.open(jsonResponse.file, '_blank');
        }

        break;

      case 'download-orders-withdrawal':
        const responseWithdrawal = await uploadOrderWithdrawal(uploadFilter);

        if ('data' in responseWithdrawal) {
          const jsonResponse = responseWithdrawal.data;

          window.open(jsonResponse.file, '_blank');
        }

        break;

      default:
        break;
    }
  };

  return {
    handleAction,
    isResendCallbackLoading,
    isDownloadLoading,
  };
};
