import { useAuth } from '@entities/auth';

import LoginBtn from './LoginBtn';
import ProfileNav from './ProfileNav';

import '../auth-control.css';

export const AuthControl = () => {
  const { isAuthenticated } = useAuth();

  return <div className='header-auth'>{isAuthenticated ? <ProfileNav /> : <LoginBtn />}</div>;
};
