import { z } from 'zod';

export const ZCurrencyMethodSchema = z.object({
  id: z.union([z.string(), z.number()]),
  code: z.string(),
  name: z.string(),
  payment_link_placeholder: z.string().nullable(),
});

export type ZCurrencyMethod = z.infer<typeof ZCurrencyMethodSchema>;
export const ZCurrencyMethodResponseSchema = z.array(ZCurrencyMethodSchema);
