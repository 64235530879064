type TAmount = string | number | null | (string | number)[];

export const formatAmount = (amount: TAmount) => {
  if (isNumeric(amount)) {
    const trimmedAmount = trimDecimalPart(amount);
    const formattedAmount = formatThousandSeparators(trimmedAmount);

    return `${formattedAmount}`;
  }

  return null;
};

const isNumeric = (value: TAmount) => {
  if (value === null) return false;

  return isFinite(Number(value));
};

export const trimDecimalPart = (value: TAmount) => {
  if (value === null) return null;

  const parsedValue = parseFloat(value.toString());

  return isNaN(parsedValue) ? null : parsedValue.toFixed(0);
};

const userLocale = navigator.language || 'en-US';
const formatThousandSeparators = (value: TAmount) => {
  if (value === null) return null;

  const numericValue = parseFloat(value.toString());

  return isNaN(numericValue) ? null : numericValue.toLocaleString(userLocale);
};
