import { useEffect } from 'react';

import { TWalletType, useWalletType } from '@entities/wallets';
import { useFilter } from '@features/table-filter';

import { useWalletsMonitoringQuery } from '../api';
import type { IWalletsMonitoringFilter } from '../model/types';

interface IProps {
  type?: TWalletType;
  refreshInterval: string;
}

export const useWalletsMonitoring = ({ type, refreshInterval }: IProps) => {
  const filter = useFilter<IWalletsMonitoringFilter>();
  const { currentFilter, updateFilter, resetFilter } = filter;

  const currentWalletType = useWalletType();
  const hasSameType = currentWalletType === type;

  // Make sure the wallet type is set before any request is made
  if (!currentFilter.get('filter[type]')) {
    updateFilter({ 'filter[type]': type });
  }

  const {
    data: walletsMonitoring,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useWalletsMonitoringQuery(currentFilter.toString(), {
    pollingInterval: refreshInterval === 'off' ? undefined : parseInt(refreshInterval),
  });

  useEffect(() => {
    if (!hasSameType) {
      resetFilter();
      updateFilter({ 'filter[type]': type });
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    walletsMonitoring,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
