import type { IProviderTableColumns } from '@entities/provider';
import { useProviders } from '@entities/provider';
import ModalOpenBtn from '@features/modal-open-btn';
import ErrorMessage from '@shared/components/ErrorMessage';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import {
  Table,
  THead,
  TBody,
  TableActions,
  TableActionsItem,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { useTableSettings } from './settings/useTableSettings';

const SettingsProvidersPage = () => {
  const {
    providers,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useProviders();

  const { columnTitles, renderRow, NewProvider } = useTableSettings({
    refetch,
  });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (providers) {
    return (
      <>
        {isFetching && <Loading />}

        <TableProvider<IProviderTableColumns> columnTitles={columnTitles} {...filter}>
          <TableActions>
            <TableActionsItem>
              <ModalOpenBtn text='New' modalContent={<NewProvider />} />
            </TableActionsItem>
          </TableActions>

          <TableContainer>
            <Table>
              <THead />
              <TBody items={providers.data} renderItem={renderRow} />
            </Table>

            <Pagination data={providers.meta} />
            <PaginationRows />
          </TableContainer>
        </TableProvider>
      </>
    );
  }

  return null;
};

export default SettingsProvidersPage;
