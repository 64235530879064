import { FC, PropsWithChildren } from 'react';

interface IProps {
  name: string;
  label: string;
}

export const FormItem: FC<PropsWithChildren<IProps>> = ({ name, label, children }) => {
  return (
    <div className='form-item'>
      <label htmlFor={name}>{label}</label>
      <div className='form-field'>{children}</div>
    </div>
  );
};
