import { FC } from 'react';

import { walletStatuses } from '@entities/wallets';
import { Select } from '@shared/components/Form';

interface IProps {
  selectedStatus?: string;
  onChangeWalletMode?: (mode: string) => void;
}

export const WalletStatusesSelect: FC<IProps> = ({ selectedStatus, onChangeWalletMode }) => (
  <Select
    name='mode'
    options={walletStatuses}
    getOptionValue={mode => mode.id}
    getOptionLabel={mode => mode.label}
    selectedValue={selectedStatus}
    onChange={onChangeWalletMode}
  />
);
