import {
  ICurrencyMethod,
  ICurrencyMethodTableColumns,
  INewCurrencyMethodQuery,
  useCreateCurrencyMethodMutation,
  useEditCurrencyMethodMutation,
} from '@entities/currency-method';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  // THead settings
  const columnTitles: ICurrencyMethodTableColumns = {
    id: 'ID',
    code: 'Code',
    name: 'Name',
    payment_link_placeholder: 'Payment Link Placeholder',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof ICurrencyMethodTableColumns, currencyMethod: ICurrencyMethod) => {
    switch (key) {
      case 'actions': {
        const excludeKeys: (keyof ICurrencyMethod)[] = [
          'id',
          'code',
          'name',
          'payment_link_placeholder',
        ];
        const entity = { ...currencyMethod, qr_link: currencyMethod.payment_link_placeholder };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Currency Method'
                entity={entity}
                excludeKeys={excludeKeys}
                useEditEntityMutation={useEditCurrencyMethodMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: <ModalViewEntity entity={currencyMethod} title='View Currency Method' />,
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return currencyMethod[key];
    }
  };

  // New merchant settings
  const newCurrencyMethodKeys: (keyof INewCurrencyMethodQuery)[] = [
    'currency_id',
    'method_id',
    'qr_link',
  ];

  const NewCurrencyMethod = () => (
    <ModalNewEntity
      title='New Currency Method'
      entityKeys={newCurrencyMethodKeys}
      useCreateEntityMutation={useCreateCurrencyMethodMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles,
    renderRow,
    NewCurrencyMethod,
  };
};
