import { baseApi } from '@app/store/index.api';

import { IWalletsMonitoringResponse } from '../model/types';

const walletsMonitoringApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    walletsMonitoring: builder.query<IWalletsMonitoringResponse, string>({
      query: query => {
        return {
          url: `/wallets/monitoring?${query}`,
        };
      },
    }),
  }),
});

export const { useWalletsMonitoringQuery } = walletsMonitoringApi;
