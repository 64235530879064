import { z } from 'zod';

export const ZCurrencySchema = z.object({
  id: z.string(),
  code: z.string(),
  title: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
  symbol: z.string(),
});

export type ZCurrency = z.infer<typeof ZCurrencySchema>;
export const ZCurrencyResponseSchema = z.array(ZCurrencySchema);
