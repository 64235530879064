import { FC } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useTableContext } from '@shared/components/Table';
import { IMeta } from '@shared/types/fetch-data';

import '../pagination.css';

interface IProps {
  data: IMeta;
}

function isPrev(label: string) {
  return label.toLowerCase().includes('prev');
}

function isNext(label: string) {
  return label.toLowerCase().includes('next');
}

export const Pagination: FC<IProps> = ({ data }) => {
  const { setPage } = useTableContext();

  if (!data.links) return null;

  return (
    <>
      <div className='pagination'>
        {data.links.map(({ active, label, url }) => {
          const page = (url ? new URL(url).searchParams.get('page') : '1') || '1';

          return (
            <div
              key={label}
              className={`page-item${active ? ' is-active' : ''}${url ? '' : ' is-disabled'}`}
              onClick={() => setPage(page)}
              onKeyDown={() => setPage(page)}
              role='button'
              tabIndex={0}
            >
              {isPrev(label) ? (
                <ArrowBackIosNewIcon />
              ) : isNext(label) ? (
                <ArrowForwardIosOutlinedIcon />
              ) : (
                label
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
