import { baseApi } from '@app/store/index.api';

import { ITransactionDepositResponse } from '../model/types';

const transactionDepositApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    transactionDeposit: builder.query<ITransactionDepositResponse, string>({
      query: query => {
        return {
          url: `/transactions/deposit?${query}`,
        };
      },
    }),
  }),
});

export const { useTransactionDepositQuery } = transactionDepositApi;
