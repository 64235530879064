import { FC } from 'react';

import { useCurrentUser } from '@entities/user';
import Card from '@shared/components/Card';
import { formatAmount } from '@shared/utils/formatAmount';
import { getCurrencySymbol } from '@shared/utils/getCurrencySymbol';

import { useDashboardContext } from '../context';

interface IProps {
  runningAmount: string;
  availableAmount: string;
}

const DashboardItemMain: FC<IProps> = ({ availableAmount, runningAmount }) => {
  const { currentUser } = useCurrentUser();
  const { currencySymbol } = useDashboardContext();
  const symbol = getCurrencySymbol(currencySymbol);

  return (
    <div
      className='dashboard-item'
      title='Total balance is your balance net reserves and hold. Available balance is the one you can use for withdrawals or settlements.'
    >
      <Card>
        <div className='dashboard-item__welcome'>Good day, {currentUser?.name}!</div>
        <p>This is your stats for today!</p>
        <div className='dashboard-item__balance'>
          <div className='dashboard-item__amount'>
            {symbol}
            {formatAmount(runningAmount)}
          </div>
          <p>Total balance</p>
        </div>
        <div className='dashboard-item__balance'>
          <div className='dashboard-item__amount'>
            {symbol}
            {formatAmount(availableAmount)}
          </div>
          <p>Available balance</p>
        </div>
      </Card>
    </div>
  );
};

export default DashboardItemMain;
