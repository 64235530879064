import { useModal } from '@shared/components/Modal';

import { ResetPassword } from './components/ResetPassword';

const ProfileSettingsWidget = () => {
  const { openModal } = useModal();

  return (
    <button className='accent-btn' onClick={() => openModal(<ResetPassword />, 'sm')}>
      Change password
    </button>
  );
};

export default ProfileSettingsWidget;
