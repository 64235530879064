import { baseApi } from '@app/store/index.api';

import { IIFSCResponse, INewIFSCResponse } from '../model/types';

const currencyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getIfsc: builder.query<IIFSCResponse, string>({
      query: query => {
        return {
          url: `ifsc?${query}`,
        };
      },
    }),
    createIfsc: builder.mutation<INewIFSCResponse, FormData>({
      query: query => {
        return {
          url: 'ifsc/import',
          method: 'POST',
          body: query,
        };
      },
    }),
  }),
});

export const { useGetIfscQuery, useCreateIfscMutation } = currencyApi;
