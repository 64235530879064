import ModalConfirm from '@features/modal-confirm';
import { useModal } from '@shared/components/Modal';
import { TUseMutationTrigger } from '@shared/types/fetch-data';

interface IProps<TQuery, TResponse> {
  id: string;
  message: string;
  deleteEntity: TUseMutationTrigger<TQuery, TResponse>;
  refetch: () => void;
}

export const ModalDeleteEntity = <Q, Res>({
  id,
  message,
  deleteEntity,
  refetch,
}: IProps<Q, Res>) => {
  const { closeModal } = useModal();

  const handleDeleteEntity = async (id: string) => {
    await deleteEntity({ id } as Q);
    closeModal();
    refetch();
  };

  const modalConfirmProps = {
    title: 'Are you sure?',
    message,
    onCancel: closeModal,
    onConfirm: () => handleDeleteEntity(id),
  };

  return <ModalConfirm {...modalConfirmProps} />;
};
