import { FC, useEffect } from 'react';

import { usePageTitle } from '@app/hooks/usePageTitle';
import useActions from '@app/store/hooks/useActions';
import {
  useLoginAdminMutation,
  useLoginMerchantMutation,
  useLoginProviderMutation,
} from '@entities/auth';
import { TUserRoles } from '@entities/user';
import { capitalizeFirstLetter } from '@shared/utils/capitalizeFirstLetter';
import { LoginForm } from '@widgets/auth';

interface IProps {
  role: TUserRoles;
}

const LoginPage: FC<IProps> = ({ role }) => {
  usePageTitle(`${capitalizeFirstLetter(role)} login`);
  const { setHasLoggedAs } = useActions();

  useEffect(() => {
    setHasLoggedAs(role);
  }, [role, setHasLoggedAs]);

  const getMutationByRole = (role: TUserRoles) => {
    switch (role) {
      case 'admin':
        return useLoginAdminMutation;
      case 'merchant':
        return useLoginMerchantMutation;
      case 'provider':
        return useLoginProviderMutation;
      default:
        throw new Error('Unknown role');
    }
  };

  const mutation = getMutationByRole(role);

  return (
    <div className='login-page'>
      <LoginForm role={role} mutation={mutation} />
    </div>
  );
};

export default LoginPage;
