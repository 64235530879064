import { useState, useEffect } from 'react';

import { formatDateAsLocalISO } from '@shared/utils/formatDateAsLocalISO';

const TODAY_INTERVAL = 14400000; // 4 hours

const useToday = () => {
  const [today, setToday] = useState(formatDateAsLocalISO(new Date()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setToday(formatDateAsLocalISO(new Date()));
    }, TODAY_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return today;
};

export default useToday;
