import { Helmet } from 'react-helmet-async';

import { useFavicon } from '../hooks/useFavicon';

const FaviconManager = () => {
  const faviconUrl = useFavicon();

  return (
    <Helmet>
      <link rel='icon' href={faviconUrl} />
    </Helmet>
  );
};

export default FaviconManager;
