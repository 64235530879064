import { useFilter } from '@features/table-filter';

import { useGetIfscQuery } from '../api';
import type { IIFSCFilter } from '../model/types';

export const useIFSC = () => {
  const filter = useFilter<IIFSCFilter>();

  const {
    data: ifscCodes,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetIfscQuery(filter.currentFilter.toString());

  return {
    ifscCodes,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
