import { baseApi } from '@app/store/index.api';

import {
  IOrderBulkResendCallbackQuery,
  IOrderBulkResendCallbackResponse,
  IOrderApproveQuery,
  IOrderApproveResponse,
} from '../model/types';

const ordersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    approveOrder: builder.mutation<IOrderApproveResponse, IOrderApproveQuery>({
      query: ({ id, actual_amount, customer_transaction_id, reason }) => {
        return {
          url: `/orders/deposits/${id}/approve-manually`,
          method: 'POST',
          body: { actual_amount, customer_transaction_id, reason },
        };
      },
    }),
    bulkResendCallbackOrder: builder.mutation<
      IOrderBulkResendCallbackResponse,
      IOrderBulkResendCallbackQuery
    >({
      query: ({ ids }) => {
        return {
          url: `/orders/statuses/send-bulk`,
          method: 'POST',
          body: { ids },
        };
      },
    }),
  }),
});

export const { useBulkResendCallbackOrderMutation, useApproveOrderMutation } = ordersApi;
