import { FC } from 'react';

interface IProps {
  refetch: () => void;
}

const RefetchBtn: FC<IProps> = ({ refetch }) => {
  return (
    <button className='accent-btn' onClick={refetch}>
      Refresh
    </button>
  );
};

export default RefetchBtn;
