import { FC } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { IObjectDeep } from '@shared/types/fetch-data';

import './data-table.css';

interface IDataDisplayProps {
  data: IObjectDeep;
  transformValue?: (value: IObjectDeep[keyof IObjectDeep]) => JSX.Element;
}

const DataTableView: FC<IDataDisplayProps> = ({ data, transformValue }) => {
  const renderTable = (obj: IObjectDeep[keyof IObjectDeep]): JSX.Element => {
    if (obj === null || obj === undefined) return <></>;

    if (typeof obj !== 'object') {
      return transformValue ? <>{transformValue(obj)}</> : <>{obj}</>;
    }

    if (Array.isArray(obj)) {
      return (
        <ul>
          {obj.map((item, index) => (
            <li key={index}>{renderTable(item)}</li>
          ))}
        </ul>
      );
    }

    return (
      <table>
        <tbody key={uuidv4()}>
          {Object.entries(obj).map(([key, value]) => (
            <tr key={key}>
              <th>{key}:</th>
              <td>{renderTable(value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return <div className='data-table-view'>{renderTable(data)}</div>;
};

export default DataTableView;
