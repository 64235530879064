import { baseApi } from '@app/store/index.api';

import { IParserTaskResponse, IParserTaskQuery } from '../model/types';

const parserTaskApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    parserTask: builder.query<IParserTaskResponse, IParserTaskQuery>({
      query: query => {
        return {
          url: `/parser-tasks/${query.type}s/${query.id}/info`,
        };
      },
    }),
  }),
});

export const { useParserTaskQuery } = parserTaskApi;
