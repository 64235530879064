import { baseApi } from '@app/store/index.api';

import {
  IDeleteMerchantQuery,
  IDeleteMerchantResponse,
  IEditMerchantQuery,
  IEditMerchantResponse,
  IMerchantResponse,
  INewMerchantResponse,
} from '../model/types';

const merchantApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMerchants: builder.query<IMerchantResponse, string>({
      query: query => {
        return {
          url: `merchants?${query}`,
        };
      },
    }),
    createMerchant: builder.mutation<INewMerchantResponse, FormData>({
      query: query => {
        return {
          url: 'merchants',
          method: 'POST',
          body: query,
        };
      },
    }),
    editMerchant: builder.mutation<IEditMerchantResponse, IEditMerchantQuery>({
      query: query => {
        return {
          url: `merchants/${query.id}`,
          method: 'PATCH',
          body: query,
        };
      },
    }),
    deleteMerchant: builder.mutation<IDeleteMerchantResponse, IDeleteMerchantQuery>({
      query: query => {
        return {
          url: `merchants/${query.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetMerchantsQuery,
  useCreateMerchantMutation,
  useEditMerchantMutation,
  useDeleteMerchantMutation,
} = merchantApi;
