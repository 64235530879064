type NumericValue = number | string;

export const calculatePercentage = (
  numerator: NumericValue,
  denominator: NumericValue,
  decimalPlaces = 2,
): number => {
  if (!denominator || Number(denominator) === 0) return 0;

  const ratio = Number(numerator) / Number(denominator);
  const percentage = ratio * 100;

  return parseFloat(percentage.toFixed(decimalPlaces));
};

export const calculateFeesPercentage = (feeCost: NumericValue, total: NumericValue): number => {
  return calculatePercentage(feeCost, total, 0);
};
