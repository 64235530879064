import { FC, useState, useEffect } from 'react';

import { useLazyGetWalletCredentialsQuery } from '@entities/wallets';
import { FormItemWithError } from '@shared/components/Form';

interface IProps {
  walletId: string;
}

export const WalletCredentials: FC<IProps> = ({ walletId }) => {
  const [credentials, setCredentials] = useState('');

  const [getWalletCredentials, { data: walletCredentials, isError, error, isSuccess }] =
    useLazyGetWalletCredentialsQuery();

  useEffect(() => {
    if (walletCredentials) {
      const credentials = JSON.parse(walletCredentials.data.credentials);

      setCredentials(credentials ? JSON.stringify(credentials, null, 2) : 'No credentials');
    }
  }, [walletCredentials]);

  const handleGetWalletCredentials = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    getWalletCredentials(walletId);
  };

  return (
    <FormItemWithError name='credentials' label='Credentials' isError={isError} error={error}>
      <textarea name='credentials' id='credentials' readOnly value={credentials} />
      <button
        className='get-wallet-credentials__btn'
        onClick={handleGetWalletCredentials}
        disabled={isSuccess}
      >
        Get credentials
      </button>
    </FormItemWithError>
  );
};
