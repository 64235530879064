import { baseApi } from '@app/store/index.api';

import {
  INewOrderTransactionResponse,
  IEditSettlementResponse,
  IEditSettlementQuery,
  IOrderTransactionResponse,
  IOrderTransactionFeeQuery,
  IOrderTransactionFeeResponse,
  IOrderTransactionFeeCountQuery,
  IOrderTransactionFeeCountResponse,
} from '../model/types';

const settlementsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSettlements: builder.query<IOrderTransactionResponse, string>({
      query: query => {
        return {
          url: `/orders/settlements?${query}`,
        };
      },
    }),
    createSettlement: builder.mutation<INewOrderTransactionResponse, FormData>({
      query: query => {
        return {
          url: `/orders/settlements`,
          method: 'POST',
          body: query,
        };
      },
    }),
    editSettlement: builder.mutation<IEditSettlementResponse, IEditSettlementQuery>({
      query: query => {
        return {
          url: `/orders/settlements/${query.id}`,
          method: 'PUT',
          body: query,
        };
      },
    }),
    getFeeByMID: builder.query<IOrderTransactionFeeResponse, IOrderTransactionFeeQuery>({
      query: query => {
        return {
          url: `/orders/settlements/fee`,
          params: query,
        };
      },
    }),
    countFee: builder.query<IOrderTransactionFeeCountResponse, IOrderTransactionFeeCountQuery>({
      query: query => {
        return {
          url: `/orders/settlements/fee/count`,
          params: query,
        };
      },
    }),
  }),
});

export const {
  useGetSettlementsQuery,
  useCreateSettlementMutation,
  useEditSettlementMutation,
  useLazyGetFeeByMIDQuery,
  useLazyCountFeeQuery,
} = settlementsApi;
