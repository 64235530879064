import { FC } from 'react';

import { useGetMetabaseChartQuery } from '@entities/metabase';
import ErrorMessage from '@shared/components/ErrorMessage';
import LoadingSpinner from '@shared/components/LoadingSpinner';

type IProps = {
  question_id: string;
  height?: string;
  width?: string;
};

export const ChartEmbed: FC<IProps> = ({ question_id, height = '600px', width = '100%' }) => {
  const {
    data: metabaseChart,
    isLoading,
    isError,
    error,
  } = useGetMetabaseChartQuery({ question_id });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (metabaseChart) {
    return (
      <iframe
        title='Monitoring Dashboard'
        src={metabaseChart.data.question_url}
        width={width}
        height={height}
        frameBorder='0'
      ></iframe>
    );
  }

  return null;
};
