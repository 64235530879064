import { TRequestType } from '@entities/requests';
import { useTransactionsDeposit } from '@entities/transactions/deposit';
import { useTransactionsWithdrawal } from '@entities/transactions/withdrawal';

export const getMutationHook = (type: TRequestType) => {
  switch (type) {
    case 'deposit':
      return useTransactionsDeposit;
    case 'withdrawal':
      return useTransactionsWithdrawal;
  }
};
