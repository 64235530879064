import { FC, useEffect } from 'react';

import { usePageTitle } from '@app/hooks/usePageTitle';
import useActions from '@app/store/hooks/useActions';
import { TWalletType } from '@entities/wallets';
import WalletsMonitoringWidget from '@widgets/wallets/monitoring';

interface IProps {
  type: TWalletType;
}

const titles = {
  deposit: 'Deposit wallets',
  withdraw: 'Withdrawal wallets',
  settlement: 'Settlement wallets',
};

const WalletsMonitoringPage: FC<IProps> = ({ type }) => {
  const title = titles[type];
  const { setWalletType } = useActions();

  usePageTitle(title);

  useEffect(() => {
    setWalletType(type);
  }, [type, setWalletType]);

  return <WalletsMonitoringWidget type={type} />;
};

export default WalletsMonitoringPage;
