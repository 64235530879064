import { useState } from 'react';

interface ValidationErrors {
  email?: string[];
  password?: string[];
}

const useLoginFormValidation = () => {
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validateFields = (email: string, password: string) => {
    const newErrors: ValidationErrors = {};

    if (!email.trim()) {
      newErrors.email = ['The email field cannot be empty.'];
    }

    if (!password.trim()) {
      newErrors.password = ['The password field cannot be empty.'];
    }

    setErrors(newErrors);
    const hasErrors = Object.keys(newErrors).length > 0;
    setIsError(hasErrors);

    return !hasErrors;
  };

  return { isError, errors, validateFields };
};

export default useLoginFormValidation;
