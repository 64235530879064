import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useMethods } from '@entities/method';
import ErrorMessage from '@shared/components/ErrorMessage';
import { Select } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  currencyId?: string;
  merchantId?: string;
  midIds?: string;

  onChangeMethod?: (methodId: string) => void;
}

export const MethodsSelect: FC<IProps> = ({
  merchantId = '',
  currencyId = '',
  midIds = '',
  onChangeMethod,
}) => {
  const { methods, isLoading, error, isFetching } = useMethods({
    merchantId,
    currencyId,
    midIds,

    disableUrlFilter: true,
  });

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (methods) {
    const hasMethods = methods.data.length > 0;

    return (
      <Select
        name='method_id'
        options={methods.data}
        getOptionValue={method => method.id}
        getOptionLabel={method => method.name}
        onChange={onChangeMethod}
        isDisabled={isFetching || !hasMethods || !currencyId}
      >
        <option value=''>Select method</option>
      </Select>
    );
  }

  return null;
};
