import { FC } from 'react';

import './error.css';

interface ValidationErrorIssue {
  message: string;
  path: string;
}

interface ValidationError {
  issues: ValidationErrorIssue[];
}

interface ZodErrorMessageProps {
  error: ValidationError;
}

export const ZodErrorMessage: FC<ZodErrorMessageProps> = ({ error }) => {
  return (
    <div className='zod-error-message'>
      <h3>API response Data structure has been changed</h3>
      {error.issues.map((issue, index) => (
        <div key={index} className='zod-error-issue'>
          <span className='zod-error-path'>{issue.path}</span>
          <span className='zod-error-message'>{issue.message}</span>
        </div>
      ))}
    </div>
  );
};
