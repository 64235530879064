import { useEffect } from 'react';

import useActions from '@app/store/hooks/useActions';

export const usePageTitle = (title: string) => {
  const { setPageTitle } = useActions();

  useEffect(() => {
    setPageTitle(title);
  }, [title, setPageTitle]);
};
