import { useGetConfigQuery } from '../api';

export const useConfig = () => {
  const { data: config, isLoading, isError, error, isFetching, refetch } = useGetConfigQuery();

  return {
    config,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,
  };
};
