import { FC } from 'react';

import './status-label.css';

interface IProps {
  status: string;
}

const RequestStatus: FC<IProps> = ({ status }) => {
  return <span className={`status-${status.toLowerCase()}`}>{status}</span>;
};

export default RequestStatus;
