import { usePageTitle } from '@app/hooks/usePageTitle';
import ApiWidget from '@widgets/settings/api';

const ApiPage = () => {
  usePageTitle('API Key & Hash Key');

  return <ApiWidget />;
};

export default ApiPage;
