import { FormEvent, useState } from 'react';

import type { TRequest } from '@entities/requests';
import { useApproveRequestMutation } from '@entities/requests';
import { FormItemWithError, FormItem } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { trimDecimalPart } from '@shared/utils/formatAmount';

import { IRequestModalContextProps } from '../model/types';

interface IProps<T> {
  request: T;
  refetch: () => void;
}

export const ApproveRequest = <T extends TRequest>({ request, refetch }: IProps<T>) => {
  const { requested_amount, actual_amount, id, status_reason, customer_transaction_id } = request;

  const [actualAmount, setActualAmount] = useState(actual_amount || requested_amount);
  const [utr, setCustomerUtr] = useState(customer_transaction_id);
  const [reason, setReason] = useState(status_reason);

  const [approveRequest, { isLoading, isError, error, isSuccess }] = useApproveRequestMutation();

  const {
    closeModal,
    additionalContext: { setSelectedIds },
  } = useModal<IRequestModalContextProps>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const result = await approveRequest({
        id,
        actual_amount: actualAmount,
        reason,
        customer_transaction_id: utr,
      });

      if ('data' in result) {
        setSelectedIds([]);
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h2>Approve Request</h2>
      <form className='popup-form' onSubmit={handleSubmit}>
        <FormItem name='requested_amount' label='Requested Amount'>
          <input
            type='text'
            name='requested_amount'
            id='requested_amount'
            defaultValue={`${trimDecimalPart(requested_amount)}`}
            readOnly
          />
        </FormItem>

        <FormItemWithError
          name='actual_amount'
          label='Actual Amount'
          isError={isError}
          error={error}
        >
          <input
            type='text'
            name='actual_amount'
            id='actual_amount'
            defaultValue={`${trimDecimalPart(actualAmount)}`}
            onChange={e => setActualAmount(e.target.value)}
          />
        </FormItemWithError>

        <FormItemWithError
          name='customer_transaction_id'
          label='UTR'
          isError={isError}
          error={error}
        >
          <input
            type='text'
            name='customer_transaction_id'
            id='customer_transaction_id'
            defaultValue={utr}
            onChange={e => setCustomerUtr(e.target.value)}
          />
        </FormItemWithError>

        <FormItemWithError name='reason' label='Reason' isError={isError} error={error}>
          <input
            type='text'
            name='reason'
            id='reason'
            defaultValue={reason}
            onChange={e => setReason(e.target.value)}
          />
        </FormItemWithError>

        {isSuccess && <div className='form-success'>Successfully approved</div>}

        <div className='form-submit'>
          <button type='submit' disabled={isLoading}>
            Approve
          </button>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};
