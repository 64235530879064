import { v4 as uuidv4 } from 'uuid';

import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';
import { isObject } from '@shared/utils/isObject';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T> {
  rows: T;
}

export const TBodyHorizontal = <T extends object>({ rows }: IProps<T>) => {
  return (
    <tbody key={uuidv4()}>
      {getTypedObjectEntries(rows).map(([key, value]) => {
        if (isObject(value)) return null;

        return (
          <tr key={String(key)}>
            <th>{transformObjectKeyToTitle(String(key))}:</th>
            <td>{String(value)}</td>
          </tr>
        );
      })}
    </tbody>
  );
};
