import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';

import { useGetBanksQuery } from '../api';
import type { IBankFilter } from '../model/types';

interface IProps {
  name?: string;
  disableUrlFilter?: boolean;
}

export const useBanks = ({ name, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<IBankFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter } = filter;

  const {
    data: banks,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetBanksQuery(currentFilter.toString());

  useEffect(() => {
    updateFilter({ 'filter[name]': name });
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    banks,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
