import { FC } from 'react';

import { Select } from '@shared/components/Form';

const refreshIntervals = [
  { id: 'off', label: 'Off' },
  { id: '5000', label: '5 seconds' },
  { id: '10000', label: '10 seconds' },
  { id: '30000', label: '30 seconds' },
  { id: '60000', label: '1 minute' },
  { id: '120000', label: '2 minutes' },
  { id: '300000', label: '5 minutes' },
];

interface IProps {
  refreshInterval: string;
  onChangeInterval: (interval: string) => void;
}

export const RefetchAuto: FC<IProps> = ({ refreshInterval, onChangeInterval }) => {
  return (
    <div className='form-item'>
      <label htmlFor='refreshInterval'>Auto Refresh</label>
      <div className='form-field'>
        <Select
          name='refreshInterval'
          options={refreshIntervals}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.label}
          selectedValue={refreshInterval}
          onChange={onChangeInterval}
        />
      </div>
    </div>
  );
};
