import { baseApi } from '@app/store/index.api';

import { IRequestDepositResponse } from '../model/types';

const requestDepositApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    requestDeposit: builder.query<IRequestDepositResponse, string>({
      query: query => {
        return {
          url: `/requests/deposits?${query}`,
        };
      },
    }),
  }),
});

export const { useRequestDepositQuery } = requestDepositApi;
