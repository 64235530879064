import { routes } from '../config';

export const findRouteInfo = (pathname: string) => {
  for (const link of routes) {
    if (link.url === pathname) {
      return link;
    }
    if (link.sublinks) {
      for (const sublink of link.sublinks) {
        if (sublink.url === pathname) {
          return sublink;
        }
      }
    }
  }
  return null;
};
