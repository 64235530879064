import { useFilter } from '@features/table-filter';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useTransactionDepositQuery } from '../api';
import type { ITransactionDepositFilter } from '../model/types';

export const useTransactionsDeposit = () => {
  const filter = useFilter<ITransactionDepositFilter>();

  const {
    data: transactions,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useTransactionDepositQuery(filter.currentFilter.toString());

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    transactions,

    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
