import {
  IIPWhitelist,
  IIPWhitelistTableColumns,
  INewIPWhitelistQuery,
  useCreateIPMutation,
  useDeleteIPMutation,
} from '@entities/merchant/ip';
import { TUserRoles, useUserRole } from '@entities/user';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import { removeKeysFromObject } from '@shared/utils/removeKeysFromObject';

import { StatusToggler } from '../components/StatusToggler';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  // THead settings
  const columnTitles: IIPWhitelistTableColumns = {
    merchant: 'Merchant',
    ip: 'IP',
    status: 'Status',
    created_at: 'Created At',
    actions: 'Actions',
  };

  const userRole = useUserRole();
  const getColumnTitles = (userRole: TUserRoles) => {
    switch (userRole) {
      case 'merchant':
        return removeKeysFromObject(columnTitles, ['status', 'actions']);

      default:
        return columnTitles;
    }
  };

  // TBody settings
  const renderRow = (key: keyof IIPWhitelistTableColumns, ip: IIPWhitelist) => {
    switch (key) {
      case 'merchant':
        return ip.merchant.name;

      case 'status':
        return <StatusToggler ip={ip} refetch={refetch} />;

      case 'actions': {
        const actionBtnsConfig: ITableActionsConfig = {
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={ip.id}
                message='Do you want to delete this IP?'
                deleteEntity={deleteIP}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return ip[key];
    }
  };

  // New merchant settings
  const [deleteIP] = useDeleteIPMutation();
  const newIPKeys: (keyof INewIPWhitelistQuery)[] = ['merchant_id', 'ip'];
  const NewIP = () => (
    <ModalNewEntity
      title='New IP'
      entityKeys={newIPKeys}
      useCreateEntityMutation={useCreateIPMutation}
      refetch={refetch}
    />
  );

  return {
    columnTitles: getColumnTitles(userRole),
    renderRow,
    NewIP,
  };
};
