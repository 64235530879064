import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DatetimeState {
  timeZone: string;
}

const initialTimezone =
  localStorage.getItem('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;

const initialState: DatetimeState = {
  timeZone: initialTimezone,
};

const datetimeSlice = createSlice({
  name: 'datetime',
  initialState,
  reducers: {
    setTimeZone: (state, action: PayloadAction<{ timeZone: string }>) => {
      localStorage.setItem('timezone', action.payload.timeZone);
      state.timeZone = action.payload.timeZone;
    },
  },
});

export const actions = datetimeSlice.actions;
export default datetimeSlice.reducer;

export const useTimeZone = () => {
  return useTypedSelector((state: RootState) => state.datetime.timeZone);
};
