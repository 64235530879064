import { FormEvent, useState, useEffect } from 'react';

import { useGenerateMerchantTokenMutation } from '@entities/merchant/me';
import CopyToClipboardButton from '@features/copy-to-clipboard-button';
import ModalConfirm from '@features/modal-confirm';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

export const ViewApiKeys = () => {
  const [apiKey, setApiKey] = useState('****************');
  const [hashKey, setHashKey] = useState('****************');

  const [generateToken, { data: merchantToken, isLoading, isSuccess, isError, error }] =
    useGenerateMerchantTokenMutation();

  const { closeModal, openModal } = useModal();

  useEffect(() => {
    if (merchantToken) {
      setApiKey(merchantToken.data.token || '');
      setHashKey(merchantToken.data.hashed_secret || '');
    }
  }, [merchantToken]);

  const handleGenerate = () => {
    generateToken();
    closeModal();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const modalConfirmProps = {
      title: 'Are you sure?',
      message: 'Do you want to generate new API keys?',
      onCancel: closeModal,
      onConfirm: handleGenerate,
    };

    openModal(<ModalConfirm {...modalConfirmProps} />, 'sm');
  };

  const warningMessage = isSuccess
    ? 'Please copy API key and Webhook key and save them securely. They are shown only once on this screen. Please use API key is to sign your requests to our API and Webhook key is to verify our callbacks to your server.'
    : 'Make sure that no live API requests are being sent right now! Previous API key and Webhook key will become invalid after you generate a new one!';

  return (
    <>
      {isLoading && <Loading />}

      <form onSubmit={handleSubmit}>
        <FormItemWithError name='apikey' label='API key' isError={isError} error={error}>
          <input type='text' readOnly value={apiKey} />
          <CopyToClipboardButton text={apiKey} />
        </FormItemWithError>
        <FormItemWithError name='hashkey' label='Webhook key' isError={isError} error={error}>
          <input type='text' readOnly value={hashKey} />
          <CopyToClipboardButton text={hashKey} />
        </FormItemWithError>
        <div className='form-submit'>
          <button className='accent-btn' type='submit' disabled={isLoading || isSuccess}>
            Generate
          </button>
        </div>
      </form>

      <div className='form-error'>{warningMessage}</div>
    </>
  );
};
